import React from 'react';
import {
    CheckboxGroupInput,
    Edit,
    ReferenceArrayInput,
    ReferenceField, required,
    SaveButton,
    SimpleForm,
    TextField, TextInput,
    Toolbar
} from 'react-admin';
import resource from "../../config/resource";

const SupportTicketEdit = (props) => {
    return (
        <Edit {...props} transform={addDateAndSenderToReply}>
            <SimpleForm toolbar={<SupportTicketEditToolbar />} redirect="show">
                <ReferenceArrayInput label="Labels" source="labels" validate={[required()]} reference={resource.supportTicketLabel}>
                    <CheckboxGroupInput optionText="text" />
                </ReferenceArrayInput>
                <ReferenceField label="User" source="user" reference={resource.user} link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField emptyText={"ندارد"} source="admin" reference={resource.user} link="show" label={"ادمین"}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="messages[0].text" label="متن گزارش" />
                <TextInput source={"messages[1].text"} label={"پاسخ"} multiline/>
            </SimpleForm>
        </Edit>
    );
};
const addDateAndSenderToReply = record => {
    const transformedRecord = {...record};

    if(record.messages[1]?.text){
        transformedRecord.messages[1].sender='admin';
        transformedRecord.messages[1].dateTime = new Date();
    }
    return transformedRecord;
}
const SupportTicketEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export default SupportTicketEdit;