import React from 'react';
import {Button, fetchRelatedRecords, useTranslate, useDataProvider, useMutation} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';

const CustomExportButton = ({record, exporter, queryResource, filter}) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [fetchList] = useMutation(
        {
            resource: queryResource,
            type: "getList",
            payload: {
                pagination: {
                    perPage: 1000,
                    page: 1
                },
                filter: typeof filter === 'function' ? filter(record) : filter
            }
        },
        {
            onSuccess: async res => exporter(res.data, fetchRelatedRecords(dataProvider))
        });

    const handleClick = () => {
        if (queryResource)
            fetchList();
        else
            exporter(record, fetchRelatedRecords(dataProvider));
    }
    return (
        <Button startIcon={<DownloadIcon/>} label={translate("ra.action.export")} onClick={handleClick}/>
    );
};

export default CustomExportButton;