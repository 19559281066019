import React from 'react';
import {Create, SimpleForm, TextInput,ReferenceArrayInput,CheckboxGroupInput} from 'react-admin';
import resource from "../../config/resource";

const SpecialTypeCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" />
                <ReferenceArrayInput source="suitableTags" reference={resource.foodTag} label="Suitable Tags" >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
                <ReferenceArrayInput source="notSuitableTags" reference={resource.foodTag} label="Not Suitable Tags" >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default SpecialTypeCreate;