import React from 'react';
import {Create, ReferenceInput, TextInput, SimpleForm, NumberInput, SelectInput} from "react-admin";
import resource from "../../config/resource";
import DatePicker from "../../components/inputs/DatePicker";

const CostLogCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect={"list"}>
                <ReferenceInput reference={resource.costSource} source={"cost"}>
                    <SelectInput optionText={"title"}/>
                </ReferenceInput>
                <TextInput source={"description"}/>
                <DatePicker source={"date"}/>
                <NumberInput source={"amount"}/>
            </SimpleForm>
        </Create>
    );
};

export default CostLogCreate;