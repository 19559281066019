import React from "react";
import { ReferenceField, TextField } from "react-admin";
import TranslateField from "./TranslateField";
import resource from "../../config/resource";
const GoalField = ({source,...props}) => {
    var goal = props.record[source]
    return (
        goal === "preDefined" ?
            <ReferenceField link={false} source="type" reference={resource.dietType}>
                <TextField source="name" />
            </ReferenceField> :
            <TranslateField source={source} {...props}/>
    );
};


GoalField.defaultProps={
    addLabel:true
}

export default GoalField;