import React from 'react';
import {
    ArrayField,
    BooleanField, ChipField,
    Datagrid,
    Filter,
    List,
    ReferenceArrayField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SingleFieldList,
    TextField,
    AutocompleteInput
} from 'react-admin';
import resource from "../../config/resource";

const PackageList = (props) => {
    return (
        <List sort={{field:"createdAt",order:"DESC"}} filters={<PackageFilter />} {...props}>
            <Datagrid rowClick="show">
                <TextField source="meal" label="meal" />
                <ReferenceField
                    source="category"
                    reference={resource.mealCategory}
                    label="Category"
                    allowEmpty
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceArrayField
                    source="types"
                    reference={resource.dietType}
                    allowEmpty
                >
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ArrayField source="foods">
                    <SingleFieldList>
                        <ReferenceField source="foodId._id" reference={resource.food} label="Food Name">
                            <ChipField source="name" />
                        </ReferenceField>
                    </SingleFieldList>
                </ArrayField>
                <BooleanField addLabel source="recommended" label="is recommended"/>
                <BooleanField addLabel source="deleted" label="is deleted"/>
            </Datagrid>
        </List>
    );
};
const PackageFilter = (props) => (
    <Filter {...props}>
        {/*<TextInput*/}
        {/*    className="Search"*/}
        {/*    label="food name"*/}
        {/*    source="foodName"*/}
        {/*    alwaysOn*/}
        {/*/>*/}
        <ReferenceInput
            source="foodId"
            reference={resource.food}
            filterToQuery={searchText => ({ q: searchText })}
            label={"food"}
            alwaysOn
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput
            source="meal"
            label="Meal"
            alwaysOn
            choices={[
                { id: "breakfast", name: "صبحانه" },
                { id: "lunch", name: "نهار" },
                { id: "dinner", name: "شام" },
                { id: "snack1", name: "میان‌وعده اول" },
                { id: "snack2", name: "میان‌وعده دوم" },
                { id: "snack3", name: "میان‌وعده سوم" },
                { id: "snack4", name: "میان‌وعده چهارم" },
            ]}
        />
        <ReferenceInput
            source="category"
            reference={resource.mealCategory}
            label="Category"
            alwaysOn
        >
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
            source="type"
            reference={resource.dietType}
            label="type"
            alwaysOn
        >
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
            source="specialType"
            reference={resource.specialType}
            label="specialType"
        >
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
            source="suitableDisease"
            reference={resource.disease}
            label="suitable Disease"
        >
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
            source="notSuitableDisease"
            reference={resource.disease}
            label="not Suitable Disease"
        >
            <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
            source="hatedFood"
            reference={resource.hatedFood}
            label="hatedFood"
        >
            <SelectInput source="name" />
        </ReferenceInput>
    </Filter>
);
export default PackageList;