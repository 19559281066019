import React from 'react';
import {
    BooleanField,
    DateField,
    FunctionField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import resource from "../../config/resource";

const DiscountShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="code" label="کد"/>
                <BooleanField addLabel source="deleted" label="پاک شده"/>
                <NumberField emptyText={"-"} source="percent" label="درصد"/>
                <NumberField source="limit" label="محدودیت تعداد"/>
                <DateField emptyText={"-"} source="expirationDate" label="تاریخ انقضا(روز)" locales={"fa-IR"}/>
                <TextField emptyText={"-"} source="description" label="توضیح"/>
                <TextField source="consumed" label="استفاده شده"/>
                <TextField source="type" label="نوع"/>
                <ReferenceField reference={resource.plan} source={"plan"}>
                    <FunctionField
                        label="plan"
                        render={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`}
                    />
                </ReferenceField>
                <DateField source="createdAt" locales="fa-IR" label="تاریخ ساخت"/>
                <DateField source="updatedAt" locales="fa-IR" label="آخرین بروزرسانی"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default DiscountShow;