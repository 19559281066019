import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

const TrackerCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="source" />
                <TextInput source="medium" />
                <TextInput source="trackerLink" />
                <TextInput source="description" />
            </SimpleForm>
        </Create>
    );
};

export default TrackerCreate;