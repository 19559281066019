import React from 'react';
import {
    BooleanField,
    Button,
    DateField,
    EditButton,
    ImageField,
    NumberField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useMutation
} from 'react-admin';
import Aside from "./Aside";
import ConditionalField from "../../components/fields/ConditionalField";
import useCustomNotify from "../../components/useCustomNotify";
import SendIcon from '@material-ui/icons/Send'

const NotificationShow = (props) => {

    return (
        <Show
            {...props}
            actions={<NotificationActions/>}
            aside={<Aside/>}
        >
            <TabbedShowLayout>
                <Tab label={"details"}>
                    <BooleanField addLabel source="deleted"/>
                    <BooleanField addLabel source="scheduleSend"/>
                    <ConditionalField condition={record => record.scheduleSend}>
                        <DateField source="scheduleSendDateTime"/>
                    </ConditionalField>
                    <TextField source="status"/>
                    <DateField source="createdAt"/>
                    <DateField source="updatedAt"/>
                    <NumberField emptyText={"-"} source="segmentCount"/>
                    <NumberField emptyText={"-"} source="sentCount"/>
                    <NumberField emptyText={"-"} source="openCount"/>
                    <NumberField emptyText={"-"} source="abTestWeight"/>
                    <ImageField emptyText={"-"} source="image" label="Image"/>
                    <TextField source="description" fullWidth/>
                </Tab>
                <Tab label={"filters"}>
                    <DateField emptyText={"-"} source="filters.registerStartDate" label="Register Start Date"/>
                    <DateField emptyText={"-"} source="filters.registerEndDate" label="Register End Date"/>
                    <TextField emptyText={"-"} source="filters.sex" label="Sex"/>
                    <TextField emptyText={"-"} source={"filters.market"}/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasPurchased" label="Has Purchased"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasWeightLog" label="Has Weight Log"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasWaterLog" label="Has Water Log"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasFoodLog" label="Has Food Log"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasSubscription" label="Has Subscription"/>
                </Tab>
                <Tab label={"Data"}>
                    <TextField emptyText={"-"} source="data.title" label="Title"/>
                    <TextField emptyText={"-"} source="data.body" label="Body" fullWidth/>
                    <TextField emptyText={"-"} source="data.actionUrl" label="Action URL"/>
                    <TextField emptyText={"-"} source="data.channel" label="Channel"/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const NotificationActions = ({basePath, data={}, resource}) => {

    const notify = useCustomNotify();
    const [sendNotification, {loading}] = useMutation();
    const onSendNotification = (id) => {
        sendNotification({
            type: 'sendNotification',
            payload: {id}
        }, {
            onSuccess: notify,
            onFailure: notify
        })
    }

    return (
        <TopToolbar>
            {data.status !== 'sent' && <Button color="primary" onClick={() => onSendNotification(data._id)} label={"ارسال"} disabled={loading} startIcon={<SendIcon/>}/>}
            <EditButton basePath={basePath} record={data}/>
        </TopToolbar>
    )
};
export default NotificationShow;