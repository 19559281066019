import React from 'react';
import {List, Datagrid, TextField,BooleanField} from 'react-admin';
const ConfigList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="key" />
                <TextField source="value" />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

export default ConfigList;