import React from 'react';
import {Show,SimpleShowLayout,TextField,BooleanField} from 'react-admin';
const ConfigShow = (props) => {
    return (
        <Show {...props} >
            <SimpleShowLayout>
                <TextField source="key" />
                <TextField source="value" />
                <BooleanField addLabel source="deleted"  />
            </SimpleShowLayout>
        </Show>
    );
};

export default ConfigShow;