import resource from "./resource";

const ADMIN_ROLE = 'admin';
const SUPPORT_ROLE = 'support';
const DIET_ADMIN_ROLE = 'diet-admin';
const MARKETING_ROLE = 'marketing';
const RECIPE_ADMIN_ROLE = 'recipe-admin';

export const permissions = {
    [resource.user]: {
        list: {
            base: [ADMIN_ROLE, SUPPORT_ROLE, DIET_ADMIN_ROLE],
            roles: [ADMIN_ROLE]
        },
        show: {
            base: [ADMIN_ROLE, SUPPORT_ROLE, DIET_ADMIN_ROLE],
            roles: [ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, SUPPORT_ROLE],
            roles: [ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, SUPPORT_ROLE]
        }
    },
    [resource.remoteConfig]: {
        list: {
            base: [ADMIN_ROLE]
        },
        show: {
            base: [ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE]
        }
    },
    [resource.abTest]: {
        list: {
            base: [ADMIN_ROLE]
        },
        show: {
            base: [ADMIN_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE]
        },
        create: {
            base: [ADMIN_ROLE]
        },
    },
    [resource.notification]: {
        list: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        show: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
    },
    [resource.automaticNotification]: {
        list: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        show: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
    },
    [resource.sms]: {
        list: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        show: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
        create: {
            base: [ADMIN_ROLE, MARKETING_ROLE]
        },
    },
    [resource.feedback]: {
        list: {
            base: [ADMIN_ROLE, SUPPORT_ROLE]
        },
        edit: {
            base: [ADMIN_ROLE]
        },
    },
    [resource.faq]:{
        list: {
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE]
        },
        create:{
            base:[ADMIN_ROLE]
        },
    },
    [resource.faqCategory]:{
        list: {
            base:[ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE]
        },
        create:{
            base:[ADMIN_ROLE]
        },
    },
    [resource.faqSubCategory]:{
        list: {
            base:[ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE]
        },
        create:{
            base:[ADMIN_ROLE]
        },
    },
    [resource.supportTicketLabel]:{
        list: {
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
        create:{
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
    },
    [resource.supportTicket]:{
        list: {
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE,SUPPORT_ROLE]
        },
    },
    [resource.exercise]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.diet]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
    },
    [resource.dietType]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        create:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
    },
    [resource.dietTypeCategory]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.specialType]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.recipeCategory]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.recipe]:{
        list: {
            base:[RECIPE_ADMIN_ROLE,DIET_ADMIN_ROLE,ADMIN_ROLE]
        },
        show:{
            base:[RECIPE_ADMIN_ROLE,DIET_ADMIN_ROLE,ADMIN_ROLE]
        },
        edit:{
            base:[RECIPE_ADMIN_ROLE,DIET_ADMIN_ROLE,ADMIN_ROLE],
            status: {
                base:[ADMIN_ROLE,DIET_ADMIN_ROLE],
                verify:[ADMIN_ROLE]
            }
        },
        create:{
            base:[RECIPE_ADMIN_ROLE,DIET_ADMIN_ROLE,ADMIN_ROLE]
        },
    },
    [resource.foodTag]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.food]:{
        list: {
            base:[ADMIN_ROLE,RECIPE_ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,RECIPE_ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE,RECIPE_ADMIN_ROLE],
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.package]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.foodUnit]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.hatedFood]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.packageCalorie]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.mealCategory]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.disease]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.recommendation]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.recommendationCategory]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[DIET_ADMIN_ROLE]
        },
        create:{
            base:[DIET_ADMIN_ROLE]
        },
    },
    [resource.discount]:{
        list: {
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        edit:{
            base:[MARKETING_ROLE]
        },
        create:{
            base:[MARKETING_ROLE]
        },
    },
    [resource.bulkDiscount]:{
        list: {
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        edit:{
            base:[MARKETING_ROLE]
        },
        create:{
            base:[MARKETING_ROLE]
        },
    },
    [resource.promotion]:{
        list: {
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        edit:{
            base:[MARKETING_ROLE]
        },
        create:{
            base:[MARKETING_ROLE]
        },
    },
    [resource.version]:{
        list: {
            base:[ADMIN_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE]
        },
    },
    [resource.tracker]:{
        list: {
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        create:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
    },
    [resource.groceryCategory]:{
        list: {
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
        create:{
            base:[ADMIN_ROLE,DIET_ADMIN_ROLE]
        },
    },
    [resource.costSource]:{
        list: {
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        show:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
        create:{
            base:[ADMIN_ROLE,MARKETING_ROLE]
        },
    },
    [resource.questCategory]:{
        list: {
            base:[ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE]
        },
        create:{
            base:[ADMIN_ROLE]
        },
    },
    [resource.quest]:{
        list: {
            base:[ADMIN_ROLE]
        },
        show:{
            base:[ADMIN_ROLE]
        },
        edit:{
            base:[ADMIN_ROLE]
        },
        create:{
            base:[ADMIN_ROLE]
        },
    },
}
