import React from 'react';
import {BooleanField, Datagrid, DateField, List, TextField} from 'react-admin';
import TextArrayField from "../../components/fields/TextArrrayField";

const VersionList = (props) => {
    return (
        <List{...props}>
            <Datagrid rowClick="edit">
                <TextField source="version" />
                <TextArrayField source={"market"}/>
                <BooleanField addLabel source="isForce" />
                <DateField source="createdAt" locales="fa-IR" />
                <DateField source="updatedAt" locales="fa-IR" />
            </Datagrid>
        </List>
    );
};

export default VersionList;