import React, {useState} from 'react';
import {getResources, Menu as RaMenu, MenuItemLink} from 'react-admin';
import {useSelector} from "react-redux";
import DefaultIcon from '@material-ui/icons/ViewList';
import resource from "../../config/resource";
import SubMenu from "./SubMenu";
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import FeedbackIcon from '@material-ui/icons/Feedback';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import DialpadIcon from '@material-ui/icons/Dialpad';
import CompareIcon from '@material-ui/icons/Compare';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import CallIcon from '@material-ui/icons/Call';
import RunIcon from '@material-ui/icons/DirectionsRun';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

const Menu = () => {
    const [openMenuKey, setOpenMenuKey] = useState(null);
    const onOpen = (key) => {
        setOpenMenuKey(openMenuKey => openMenuKey === key ? null : key);
    }
    const resources = useSelector(getResources);
    const getResource = (resourceName) => {
        return resources.filter(resource => resourceName === resource.name)[0];
    }
    const resourceMenu = [
        {
            ...getResource(resource.user),
            icon: <PeopleAltRoundedIcon/>,
            label: 'کاربران'
        },
        {
            label: 'UX',
            icon: <CompareIcon/>,
            children: [
                {
                    ...getResource(resource.remoteConfig),
                    label: 'Remote Config'
                },
                {
                    ...getResource(resource.abTest),
                    label: 'AB Test'
                },
                {
                    ...getResource(resource.automaticNotification),
                    label: 'Automatic Notification'
                },
                {
                    ...getResource(resource.automaticSms),
                    label: 'Automatic Sms'
                }
            ]
        },
        {
            label: 'بازاریابی',
            icon: <CallIcon/>,
            children: [
                {
                    ...getResource(resource.notification),
                    label: 'Notification'
                },
                {
                    ...getResource(resource.sms),
                    label: 'SMS'
                },
                {
                    ...getResource(resource.tracker),
                    label: 'Tracker'
                }
            ]
        },
        {
            label: 'تخفیف',
            icon: <MoneyIcon/>,
            children: [
                {
                    ...getResource(resource.discount),
                    label: 'Discount'
                },
                {
                    ...getResource(resource.bulkDiscount),
                    label: 'Bulk Discount'
                },
                {
                    ...getResource(resource.promotion),
                    label: 'Promotion'
                }
            ]
        },
        {
            label: 'پشتیبانی',
            icon: <FeedbackIcon/>,
            children: [
                {
                    ...getResource(resource.faqCategory),
                    label: 'Faq Category'
                },
                {
                    ...getResource(resource.faqSubCategory),
                    label: 'Faq Subcategory'
                },
                {
                    ...getResource(resource.faq),
                    label: 'Faq'
                },
                {
                    ...getResource(resource.feedback),
                    label: 'Feedback'
                },
                {
                    ...getResource(resource.supportTicket),
                    label: 'Support Ticket'
                },
                {
                    ...getResource(resource.supportTicketLabel),
                    label: 'Support Ticket Label'
                }
            ]
        },
        {
            label: 'رژیم',
            icon: <LocalDiningIcon/>,
            children: [
                {
                    ...getResource(resource.diet),
                    label: 'Diet'
                },
                {
                    ...getResource(resource.dietType),
                    label: 'Diet Type'
                },
                // {
                //     ...getResource(resource.predefinedDiet),
                //     label:'Predefined Diet'
                // },
                {
                    ...getResource(resource.dietTypeCategory),
                    label: 'Diet Type Category'
                },
                {
                    ...getResource(resource.specialType),
                    label: 'Special Type'
                }
            ]
        },
        {
            label: 'غذا',
            icon: <RestaurantIcon/>,
            children: [
                {
                    ...getResource(resource.food),
                    label: 'Food'
                },
                {
                    ...getResource(resource.foodTag),
                    label: 'Food Tag'
                },
                {
                    ...getResource(resource.package),
                    label: 'Package'
                },
                {
                    ...getResource(resource.foodUnit),
                    label: 'Food Unit'
                },
                {
                    ...getResource(resource.hatedFood),
                    label: 'Hated Food'
                },
                {
                    ...getResource(resource.packageCalorie),
                    label: 'Package Calorie'
                },
                {
                    ...getResource(resource.mealCategory),
                    label: 'Meal Category'
                },
                {
                    ...getResource(resource.disease),
                    label: 'Disease'
                }
            ]
        },
        {
            label: 'دستور پخت',
            icon: <FastfoodIcon/>,
            children: [
                {
                    ...getResource(resource.recipe),
                    label: 'Recipe'
                },
                {
                    ...getResource(resource.recipeCategory),
                    label: 'Recipe Category'
                }
            ]
        },
        {
            ...getResource(resource.exercise),
            icon: <RunIcon/>,
            label: 'ورزش'
        },
        {
            ...getResource(resource.groceryCategory),
            icon: <ShoppingCart/>,
            label: 'دسته‌بندی خرید'
        },
        {
            label: 'هزینه',
            icon: <MoneyIcon/>,
            children: [
                {
                    ...getResource(resource.costSource),
                    label: 'Cost'
                },
                {
                    ...getResource(resource.costLog),
                    label: 'Cost Log'
                }
            ]
        },
        {
            ...getResource(resource.version),
            icon: <DialpadIcon/>,
            label: 'ورژن'
        },
        {
            label: 'Quest',
            icon: <MoneyIcon/>,
            children: [
                {
                    ...getResource(resource.questCategory),
                    label: 'Quest Category'
                },
                {
                    ...getResource(resource.quest),
                    label: 'Quest'
                },
            ]
        },

    ]

    const hasAccessToSubmenu = (item) => {
        for (let child of item.children) {
            if (child.hasList)
                return true;
        }
        return false;
    }

    return (
        <RaMenu>
            {resourceMenu.map((item, index) =>
                item.children ? (
                    hasAccessToSubmenu(item) &&
                    <SubMenu open={openMenuKey === item.label} onOpen={onOpen} key={index} item={item}/>
                ) : (
                    item.hasList && <MenuItemLink
                        key={index}
                        to={`/${item.name}`}
                        primaryText={
                            item.label || item.name
                        }
                        leftIcon={
                            item.icon || <DefaultIcon/>
                        }
                    />
                )
            )}
        </RaMenu>
    );
};

export default Menu;