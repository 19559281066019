import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ReferenceField,
    SelectInput,
    TextField,
    FunctionField
} from 'react-admin';
import resource from "../../config/resource";

const DiscountList = (props) => {
    return (
        <List filters={<DiscountFilter />} {...props}>
            <Datagrid rowClick="edit">
                <DateField emptyText={"-"} source="expirationDate" locales="fa-IR" label="تاریخ انقضا" />
                <BooleanField addLabel source="deleted" label="پاک شده" />
                <DateField source="createdAt" locales="fa-IR" label="تاریخ ساخت" />
                <DateField source="updatedAt" locales="fa-IR" label="آخرین بروزرسانی" />
                <NumberField emptyText={"-"} source="percent" label="درصد" locales="fa-IR" />
                <NumberField source="limit" label="محدودیت" locales="fa-IR" />
                <NumberField source="consumed" label="مصرف شده" locales="fa-IR" />
                <TextField source="code" label="کد" />
                <TextField emptyText={"-"} source="description" label="توضیح" />
                <TextField source="type" label="نوع" />
                <ReferenceField reference={resource.plan} source={"plan"} emptyText={"همه"}>
                    <FunctionField
                        label="plan"
                        render={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`}
                    />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};
const DiscountFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="type"
            label="نوع"
            alwaysOn
            choices={[
                { id: "normal", name: "Normal" },
                { id: "automated", name: "Automated" },
                { id: "gift", name: "Gift" },
            ]}
        />
    </Filter>
);
export default DiscountList;