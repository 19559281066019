import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ArrayField,
    NumberField,
    ReferenceField,
    DateField,
    BooleanField,
} from 'react-admin';
import resource from "../../config/resource";
const AbTestList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick="show" size={"medium"}>
                <TextField source="description" />
                <ArrayField source="variants">
                    <Datagrid>
                        <TextField source="name" />
                        <NumberField source="weight" />
                        <ArrayField label="Remote Configs" source="remoteConfigs">
                            <Datagrid size={"medium"}>
                                <ReferenceField link={false} source="key" reference={resource.remoteConfig} >
                                    <TextField source="key" />
                                </ReferenceField>
                                <TextField source="value" />
                            </Datagrid>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>
                <DateField source="startDate" locales={["fa-IR"]} />
                <DateField source="endDate" locales={["fa-IR"]} />
                <BooleanField addLabel source="enable" />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

export default AbTestList;