import React from 'react';
import {
    Edit,
    TextField,
    ReferenceArrayInput,
    CheckboxGroupInput,
    DateField,
    NumberInput,
    Datagrid,
    ReferenceManyField,
    ReferenceField,
    BooleanField,
    ReferenceArrayField,
    NumberField, TextInput, Pagination, required, TabbedForm, FormTab
} from 'react-admin';
import TranslateField from "../../components/fields/TranslateField";
import PriceField from "../../components/fields/PriceField";
import RefundButton from "./RefundButton";
import DeleteSubscriptionButton from "./DeleteSubscriptionButton";
import EditToolbar from "../../components/EditToolbar";
import resource from "../../config/resource";
import CustomFormTab from "../../components/form/CustomFormTab";
import {Grid} from "@material-ui/core";
import usePermission from "../../components/permission/usePermission";

const UserEdit = (props) => {
    const hasPermission = usePermission();
    return (
        <Edit {...props}>
            <TabbedForm redirect="show" toolbar={<EditToolbar/>}>
                <CustomFormTab label={"اطلاعات کاربری"}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField source="name" label="نام"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField source="username" label="شماره تلفن"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField source="email" label="ایمیل" emptyText={"-"}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TextField source="usernamePassword" label="کد عبور"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <DateField locales="fa-IR" source="createdAt" label="زمان ثبت نام"/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <DateField
                                locales="fa-IR"
                                source="updatedAt"
                                label="زمان آخرین بروزرسانی"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={2}>
                            <TranslateField source={"sex"}/>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item>
                                <NumberInput locales="fa-IR" source="height" label="قد"/>
                            </Grid>
                            <Grid item>
                                <NumberInput locales="fa-IR" source="weight" label="وزن"/>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} alignItems={"center"} spacing={2}>
                            <Grid item>
                                <DateField label={"اشتراک"} source={'subscription.expirationDate'} emptyText={"-"} locales="fa-IR"/>
                                <DeleteSubscriptionButton/>
                                <NumberInput label={"افزودن اشتراک (ماه)"} source={'subscriptionMonths'}/>
                            </Grid>
                            <Grid item>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {hasPermission('user.edit.roles') ? (
                                <ReferenceArrayInput validate={required()} source="roles" reference={resource.role}
                                                     label="نوع کاربر">
                                    <CheckboxGroupInput source="name"/>
                                </ReferenceArrayInput>
                            ) : null}
                        </Grid>
                    </Grid>

                </CustomFormTab>
                <FormTab label={"خرید‌های موفق"}>
                    <ReferenceManyField label="" reference={resource.invoice} filter={{purchased: true}} target="user"
                                        pagination={<Pagination/>}>
                        <Datagrid rowClick="">
                            <DateField locales="fa-IR" source="createdAt" label="زمان خرید"/>
                            <PriceField source="amount" label="قیمت خریداری شده"/>
                            <ReferenceField
                                source="plan"
                                reference={resource.plan}
                                label="قیمت اشتراک"
                                link={false}
                            >
                                <PriceField source="price"/>
                            </ReferenceField>
                            <ReferenceField
                                source="plan"
                                reference={resource.plan}
                                label="نوع اشتراک"
                                link={false}
                            >
                                <TextField source="title"/>
                            </ReferenceField>
                            <ReferenceField emptyText={"-"} link={false} source="promotion"
                                            reference={resource.promotion}>
                                <TextField source="title"/>
                            </ReferenceField>
                            <TranslateField source={"market"} label={"مارکت"}/>
                            <ReferenceField emptyText={"-"} link={false} source="discount" reference={resource.discount}
                                            label="درصد تخفیف">
                                <NumberField source="percent" locales="fa-IR"/>
                            </ReferenceField>
                            <ReferenceField emptyText={"-"} link={false} source="discount" reference={resource.discount}
                                            label="کد تخفیف">
                                <TextField source="code"/>
                            </ReferenceField>
                            <BooleanField addLabel source="refund" label="بازپرداخت"/>
                            <RefundButton/>
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label={"یادداشت ها"}>
                    <ReferenceArrayField source="notes" reference={resource.note} label="" fullWidth>
                        <Datagrid rowClick="edit">
                            <DateField locales="fa-IR" source="createdAt" label="زمان ساخت"/>
                            <DateField
                                locales="fa-IR"
                                source="updatedAt"
                                label="زمان آخرین بروزرسانی"
                            />
                            <ReferenceField reference={resource.user} source="author" label="نویسنده">
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="text" label="متن یادداشت"/>
                        </Datagrid>
                    </ReferenceArrayField>
                </FormTab>
                <FormTab label={"یادداشت جدید"}>
                    <TextInput source="note" label="یادداشت جدید"/>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};


export default UserEdit;