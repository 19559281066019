import {
    List,
    Datagrid,
    Filter,
    NumberInput,
    TextInput,
    EmailField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    TextField, DateField,
} from 'react-admin';
import React from "react";
import resource from "../../config/resource";
import usePermission from "../../components/permission/usePermission";
export const UserList = (props) => {
    const hasPermission = usePermission();
    return (
        <List filters={<UserFilter/>} bulkActionButtons={false} {...props}>
            <Datagrid rowClick="show" size={"medium"}>
                <TextField source="name" label="نام" emptyText={"-"}/>
                <TextField source="username" label="شماره تلفن" emptyText={"-"}/>
                <EmailField source="email" label="ایمیل" emptyText={"-"}/>
                <TextField source="usernamePassword" label="کد عبور" emptyText={"-"}/>
                <DateField locales={"fa-IR"} emptyText={"ندارد"} source={"subscription.expirationDate"} label="اشتراک"/>
                {hasPermission(`${resource.user}.list.roles`) && (
                    <ReferenceArrayField source="roles" reference={resource.role} label="نوع کاربر">
                        <SingleFieldList linkType={false}>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                )}
            </Datagrid>
        </List>
    )
};

const UserFilter = (props) => (
    <Filter {...props}>
        <NumberInput
            label="شماره تلفن"
            source="Username"
            alwaysOn
        />
        <TextInput
            label="ایمیل"
            source="email"
            alwaysOn
        />
    </Filter>
);