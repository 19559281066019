import React from 'react';
import {
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    NumberInput,
    SelectInput, SimpleForm,
    SimpleFormIterator,
    TextInput
} from "react-admin";

const VersionForm = (props) => {
    return (
        <SimpleForm {...props}>
            <NumberInput source="version"/>
            <CheckboxGroupInput source={"market"} choices={[
                {id: "play", name: "Google Play"},
                {id: "bazaar", name: "Cafe Bazaar"},
                {id: "myket", name: "Myket"},
                {id: "pwa", name: "PWA"},
                {id: "ios", name: "IOS"},
                {id: "sam", name: "sam"},
            ]}/>
            <ArrayInput source="versionLog">
                <SimpleFormIterator>
                    <SelectInput label={"type"} source={"type"} choices={[
                        {id: 'added',name:'added'},
                        {id: 'changed',name:'changed'},
                        {id: 'deprecated',name:'deprecated'},
                        {id: 'fixed',name:'fixed'},
                        {id: 'removed',name:'removed'},
                        {id: 'security',name:'security'},
                        {id: 'dependency',name:'dependency'},
                    ]}/>
                    <TextInput label={"text"} source="text"/>
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="isForce"/>
        </SimpleForm>
    );
};

export default VersionForm;