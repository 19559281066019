import React, {useState} from 'react';
import {FileInput, FileField, ImageField, useMutation} from "react-admin";
import {useForm} from 'react-final-form';
import {Box} from "@material-ui/core";
import useCustomNotify from "../useCustomNotify";

const Upload = ({source, segment,type='image',record,resource}) => {
    const [uploadFile] = useMutation();
    const form = useForm();
    const notify = useCustomNotify();
    const [progress,setProgress] = useState({});
    const onUploadProgress = (values) => {
        setProgress(values)
    }
    const handleUpload = (file) => {
        uploadFile({
            type: "upload",
            payload: {file, onUploadProgress,segment},
        },{
            onSuccess:res => form.change(source, res.data.src),
            onFailure:notify
        })
    }
    const isUploading = progress.total !== progress.loaded;
    return (
        <div>
            {type === 'image' ?
                <FileInput
                    source={source}
                    // label="Upload file (.jpg/.png)"
                    accept=".jpg, .png, .jpeg"
                    format={(value) => {
                        if(value && !value.src)
                        return {
                            src:value
                        }
                        return value
                    }}
                    parse={(value) => {
                        handleUpload(value)
                    }}
                >
                    <ImageField source={"src"}/>
                </FileInput> :
                <FileInput source={source} accept="video/*" format={(value) => {
                    if(value && !value.src)
                        return {
                            src:value
                        }
                    return value
                }}
                           parse={(value) => {
                               handleUpload(value)
                           }}>
                    <FileField source={"src"} title={"فایل"}/>
                </FileInput>
            }
            <Box minHeight={50}>
                {isUploading && <progress max={progress.total} value={progress.loaded}/>}
            </Box>
        </div>
    );
};

export default Upload;