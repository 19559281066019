import React from 'react';
import {BooleanField, Datagrid, DateField, List, ReferenceArrayField, SingleFieldList, TextField} from 'react-admin';
import resource from "../../config/resource";

const SpecialTypeList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <ReferenceArrayField source="suitableTags" reference={resource.foodTag} >
                    <SingleFieldList linkType={false}>
                        <TextField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="notSuitableTags" reference={resource.foodTag} >
                    <SingleFieldList linkType={false}>
                        <TextField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <DateField source="updatedAt" locales={"fa-IR"} />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

export default SpecialTypeList;