import React from 'react';
import {Datagrid, List, TextField} from 'react-admin';

const CostSourceList = (props) => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick={"show"}>
                <TextField source={"title"}/>
            </Datagrid>
        </List>
    );
};

export default CostSourceList;