import resource from "./resource";
import UserService from "../services/user";
import Service from "../services/Service";
import DietTypeService from "../services/dietType";
import FaqService from "../services/faq";
import VersionService from "../services/version";
import NotificationService from "../services/notification";
import SmsService from "../services/sms";
import SubscriptionService from "../services/subscription";
import AutomaticNotificationService from "../services/automaticNotification";
import BulkDiscountService from "../services/bulkDiscount";
import AutomaticSmsService from "../services/automaticSms";
import CostLogService from "../services/costLogService";
import PackageService from "../services/package";

const api = {
    [resource.user]:new UserService("/admin/user"),
    [resource.role]:new Service("/admin/role"),
    [resource.diet]:new Service("/admin/diet"),
    [resource.invoice]:new Service("/admin/invoice"),
    [resource.plan]:new Service("/admin/plan"),
    [resource.promotion]:new Service("/promotion"),
    [resource.discount]:new Service("/discount"),
    [resource.dietType]:new DietTypeService("/dietType"),
    [resource.supportTicket]:new Service("/supportTicket"),
    [resource.supportTicketLabel]:new Service("/supportTicket/label"),
    [resource.faq]:new FaqService("/faq"),
    [resource.note]:new Service("/note"),
    [resource.log]:new Service("/actionLog"),
    [resource.subscription]:new SubscriptionService("/subscription"),
    [resource.remoteConfig]:new Service("/abtest/remoteconfig"),
    [resource.abTest]:new Service("/abtest"),
    [resource.feedback]:new Service("/feedback"),
    [resource.faqSubCategory]: new Service("/faq/subcategory"),
    [resource.faqCategory]: new Service("/faq/category"),
    [resource.exercise]: new Service("/exercise"),
    [resource.dietTypeCategory]:new Service("/dietTypeCategory"),
    [resource.packageCalorie]:new Service("/package/packageCalorie"),
    [resource.package]:new PackageService("/package"),
    [resource.specialType]: new Service("/food/specialType"),
    [resource.recipeCategory]:new Service("/food/category"),
    [resource.foodTag]:new Service("/food/tag"),
    [resource.foodUnit]:new Service("/food/foodUnit"),
    [resource.hatedFood]: new Service("/food/hatedFood"),
    [resource.mealCategory]:new Service("/package/category"),
    [resource.disease]:new Service("/food/disease"),
    [resource.recommendation]:new Service("/recommendation"),
    [resource.recommendationCategory]:new Service("/recommendation/category"),
    [resource.bulkDiscount]:new BulkDiscountService("/bulkdiscount"),
    [resource.version]:new VersionService("/version"),
    [resource.tracker]:new Service("/tracker"),
    [resource.food]:new Service("/food"),
    [resource.notification]:new NotificationService("/notification"),
    [resource.automaticNotification]:new AutomaticNotificationService("/automaticNotification"),
    [resource.sms]:new SmsService("/sms"),
    [resource.recipe]:new Service("/recipe"),
    [resource.groceryCategory]:new Service("/grocerycategory"),
    [resource.automaticSms]:new AutomaticSmsService("/automaticSms"),
    [resource.costSource]:new Service("/cost"),
    [resource.costLog]:new CostLogService("/cost/log"),
    [resource.questCategory]:new Service("/quest/category"),
    [resource.quest]:new Service("/quest"),
}
export default api;