import React from 'react';
import {Create} from "react-admin";
import VersionForm from "./VersionForm";

const VersionCreate = (props) => {
    return (
        <Create {...props}>
            <VersionForm/>
        </Create>
    );
};

export default VersionCreate;