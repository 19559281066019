import React from 'react';
import {Edit} from 'react-admin';
import QuestCategoryForm from "./QuestCategoryForm";

const QuestCategoryEdit = (props) => {
    return (
        <Edit {...props}>
            <QuestCategoryForm/>
        </Edit>
    );
};

export default QuestCategoryEdit;