import {Admin} from "react-admin";
import CustomLayout from "./components/Layout/CustomLayout";
import Login from "./pages/login/login";
import dataProvider from "./config/dataProvider";
import authProvider from "./config/authProvider";
import {UserList} from "./pages/user/UserList";
import UserShow from "./pages/user/UserShow";
import {StylesProvider} from '@material-ui/core/styles';
import {jss, theme} from "./config/theme";
import i18nProvider from "./config/i18nProvider";
import JalaliUtils from "@date-io/jalaali";
import moment from 'moment-jalaali';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import UserCreate from "./pages/user/UserCreate";
import UserEdit from "./pages/user/UserEdit";
import ConfigList from "./pages/remoteconfig/ConfigList";
import ConfigShow from "./pages/remoteconfig/ConfigShow";
import ConfigEdit from "./pages/remoteconfig/ConfigEdit";
import ConfigCreate from "./pages/remoteconfig/ConfigCreate";
import AbTestList from "./pages/abtest/AbTestList";
import AbTestShow from "./pages/abtest/AbTestShow";
import AbTestEdit from "./pages/abtest/AbTestEdit";
import AbTestCreate from "./pages/abtest/AbTestCreate";
import AuthorizedResource from "./components/permission/AuthorizedResource";
import FeedbackEdit from "./pages/feedback/FeedbackEdit";
import FeedbackList from "./pages/feedback/FeedbackList";
import FaqList from "./pages/faq/FaqList";
import FaqShow from "./pages/faq/FaqShow";
import FaqCreate from "./pages/faq/FaqCreate";
import FaqEdit from "./pages/faq/FaqEdit";
import FaqCategoryList from "./pages/faqCategory/FaqCategoryList";
import FaqCategoryShow from "./pages/faqCategory/FaqCategoryShow";
import FaqCategoryCreate from "./pages/faqCategory/FaqCategoryCreate";
import FaqCategoryEdit from "./pages/faqCategory/FaqCategoryEdit";
import FaqSubcatList from "./pages/faqSubcat/FaqSubcatList";
import FaqSubcatShow from "./pages/faqSubcat/FaqSubcatShow";
import FaqSubcatCreate from "./pages/faqSubcat/FaqSubcatCreate";
import FaqSubcatEdit from "./pages/faqSubcat/FaqSubcatEdit";
import SupportTicketLabelList from "./pages/supportTicketLabel/SupportTicketLabelList";
import SupportTicketLabelShow from "./pages/supportTicketLabel/SupportTicketLabelShow";
import SupportTicketLabelCreate from "./pages/supportTicketLabel/SupportTicketLabelCreate";
import SupportTicketLabelEdit from "./pages/supportTicketLabel/SupportTicketLabelEdit";
import SupportTicketList from "./pages/supportTicket/SupportTicketList";
import SupportTicketShow from "./pages/supportTicket/SupportTicketShow";
import SupportTicketEdit from "./pages/supportTicket/SupportTicketEdit";
import ExerciseList from "./pages/exercise/ExerciseList";
import ExerciseShow from "./pages/exercise/ExerciseShow";
import ExerciseEdit from "./pages/exercise/ExerciseEdit";
import ExerciseCreate from "./pages/exercise/ExerciseCreate";
import DietList from "./pages/diet/DietList";
import DietShow from "./pages/diet/DietShow";
import DietTypeList from "./pages/dietType/DietTypeList";
import DietTypeShow from "./pages/dietType/DietTypeShow";
import DietTypeEdit from "./pages/dietType/DietTypeEdit";
import DietTypeCreate from "./pages/dietType/DietTypeCreate";
import DietTypeCategoryList from "./pages/dietTypeCategory/DietTypeCategoryList";
import DietTypeCategoryShow from "./pages/dietTypeCategory/DietTypeCategoryShow";
import DietTypeCategoryCreate from "./pages/dietTypeCategory/DietTypeCategoryCreate";
import DietTypeCategoryEdit from "./pages/dietTypeCategory/DietTypeCategoryEdit";
import SpecialTypeList from "./pages/specialType/SpecialTypeList";
import SpecialTypeShow from "./pages/specialType/SpecialTypeShow";
import SpecialTypeEdit from "./pages/specialType/SpecialTypeEdit";
import SpecialTypeCreate from "./pages/specialType/SpecialTypeCreate";
import RecipeCatsList from "./pages/recipeCats/RecipeCatsList";
import RecipeCatsShow from "./pages/recipeCats/RecipeCatsShow";
import RecipeCatsEdit from "./pages/recipeCats/RecipeCatsEdit";
import RecipeCatsCreate from "./pages/recipeCats/RecipeCatsCreate";
import FoodTagList from "./pages/foodTag/FoodTagList";
import FoodTagShow from "./pages/foodTag/FoodTagShow";
import FoodTagEdit from "./pages/foodTag/FoodTagEdit";
import FoodTagCreate from "./pages/foodTag/FoodTagCreate";
import FoodUnitList from "./pages/foodUnit/FoodUnitList";
import FoodUnitShow from "./pages/foodUnit/FoodUnitShow";
import FoodUnitEdit from "./pages/foodUnit/FoodUnitEdit";
import FoodUnitCreate from "./pages/foodUnit/FoodUnitCreate";
import HatedFoodList from "./pages/hatedFood/HatedFoodList";
import HatedFoodShow from "./pages/hatedFood/HatedFoodShow";
import HatedFoodEdit from "./pages/hatedFood/HatedFoodEdit";
import HatedFoodCreate from "./pages/hatedFood/HatedFoodCreate";
import PackageCalorieList from "./pages/packageCalorie/PackageCalorieList";
import PackageCalorieShow from "./pages/packageCalorie/PackageCalorieShow";
import PackageCalorieEdit from "./pages/packageCalorie/PackageCalorieEdit";
import PackageCalorieCreate from "./pages/packageCalorie/PackageCalorieCreate";
import MealCategoryList from "./pages/mealCategory/MealCategoryList";
import MealCategoryShow from "./pages/mealCategory/MealCategoryShow";
import MealCategoryEdit from "./pages/mealCategory/MealCategoryEdit";
import MealCategoryCreate from "./pages/mealCategory/MealCategoryCreate";
import DiseaseList from "./pages/disease/DiseaseList";
import DiseaseShow from "./pages/disease/DiseaseShow";
import DiseaseEdit from "./pages/disease/DiseaseEdit";
import DiseaseCreate from "./pages/disease/DiseaseCreate";
import RecommendationList from "./pages/recommendation/RecommendationList";
import RecommendationShow from "./pages/recommendation/RecommendationShow";
import RecommendationEdit from "./pages/recommendation/RecommendationEdit";
import RecommendationCreate from "./pages/recommendation/RecommendationCreate";
import RecommendationCategoryList from "./pages/recommendationCategory/RecommendationCategoryList";
import RecommendationCategoryShow from "./pages/recommendationCategory/RecommendationCategoryShow";
import RecommendationCategoryEdit from "./pages/recommendationCategory/RecommendationCategoryEdit";
import RecommendationCategoryCreate from "./pages/recommendationCategory/RecommendationCategoryCreate";
import DiscountList from "./pages/discount/DiscountList";
import DiscountShow from "./pages/discount/DiscountShow";
import DiscountEdit from "./pages/discount/DiscountEdit";
import DiscountCreate from "./pages/discount/DiscountCreate";
import BulkdiscountList from "./pages/bulkdiscount/BulkdiscountList";
import BulkdiscountShow from "./pages/bulkdiscount/BulkdiscountShow";
import BulkdiscountEdit from "./pages/bulkdiscount/BulkdiscountEdit";
import BulkdiscountCreate from "./pages/bulkdiscount/BulkdiscountCreate";
import PromotionList from "./pages/promotion/PromotionList";
import PromotionShow from "./pages/promotion/PromotionShow";
import PromotionEdit from "./pages/promotion/PromotionEdit";
import PromotionCreate from "./pages/promotion/PromotionCreate";
import VersionList from "./pages/version/VersionList";
import VersionEdit from "./pages/version/VersionEdit";
import TrackerList from "./pages/tracker/TrackerList";
import TrackerShow from "./pages/tracker/TrackerShow";
import TrackerEdit from "./pages/tracker/TrackerEdit";
import TrackerCreate from "./pages/tracker/TrackerCreate";
import resource from "./config/resource";
import PackageList from "./pages/package/PackageList";
import PackageShow from "./pages/package/PackageShow";
import PackageCreate from "./pages/package/PackageCreate";
import PackageEdit from "./pages/package/PackageEdit";
import FoodList from "./pages/food/FoodList";
import FoodEdit from "./pages/food/FoodEdit";
import NoteEdit from "./pages/note/NoteEdit";
import FoodCreate from "./pages/food/FoodCreate";
import NotificationList from "./pages/notification/NotificationList";
import NotificationCreate from "./pages/notification/NotificationCreate";
import NotificationShow from "./pages/notification/NotificationShow";
import NotificationEdit from "./pages/notification/NotificationEdit";
import AutomaticNotificationList from "./pages/automaticNotification/AutomaticNotificationList";
import AutomaticNotificationCreate from "./pages/automaticNotification/AutomaticNotificationCreate";
import AutomaticNotificationEdit from "./pages/automaticNotification/AutomaticNotificationEdit";
import AutomaticNotificationShow from "./pages/automaticNotification/AutomaticNotificationShow";
import SmsList from "./pages/sms/SmsList";
import SmsShow from "./pages/sms/SmsShow";
import SmsEdit from "./pages/sms/SmsEdit";
import SmsCreate from "./pages/sms/SmsCreate";
import RecipeList from "./pages/recipe/RecipeList";
import RecipeShow from "./pages/recipe/RecipeShow";
import RecipeCreate from "./pages/recipe/RecipeCreate";
import RecipeEdit from "./pages/recipe/RecipeEdit";
import FoodShow from "./pages/food/FoodShow";
import VersionShow from "./pages/version/VersionShow";
import VersionCreate from "./pages/version/VersionCreate";
import GroceryCategoryList from "./pages/groceryCategory/GroceryCategoryList";
import GroceryCategoryShow from "./pages/groceryCategory/GroceryCategoryShow";
import GroceryCategoryEdit from "./pages/groceryCategory/GroceryCategoryEdit";
import GroceryCategoryCreate from "./pages/groceryCategory/GroceryCategoryCreate";
import AutomaticSmsList from "./pages/automaticSms/AutomaticSmsList";
import AutomaticSmsShow from "./pages/automaticSms/AutomaticSmsShow";
import AutomaticSmsEdit from "./pages/automaticSms/AutomaticSmsEdit";
import AutomaticSmsCreate from "./pages/automaticSms/AutomaticSmsCreate";
import CostSourceList from "./pages/costSource/CostSourceList";
import CostSourceShow from "./pages/costSource/CostSourceShow";
import CostSourceEdit from "./pages/costSource/CostSourceEdit";
import CostSourceCreate from "./pages/costSource/CostSourceCreate";
import CostLogList from "./pages/costLog/CostLogList";
import CostLogShow from "./pages/costLog/CostLogShow";
import CostLogEdit from "./pages/costLog/CostLogEdit";
import CostLogCreate from "./pages/costLog/CostLogCreate";
import QuestCategoryList from "./pages/questCategory/QuestCategoryList";
import QuestCategoryShow from "./pages/questCategory/QuestCategoryShow";
import QuestCategoryEdit from "./pages/questCategory/QuestCategoryEdit";
import QuestCategoryCreate from "./pages/questCategory/QuestCategoryCreate";
import QuestList from "./pages/quest/QuestList";
import QuestShow from "./pages/quest/QuestShow";
import QuestEdit from "./pages/quest/QuestEdit";
import QuestCreate from "./pages/quest/QuestCreate";

moment.loadPersian({dialect: "persian-modern", usePersianDigits: true});

function App() {
    return (
        <MuiPickersUtilsProvider utils={JalaliUtils} locale="fa">
            <StylesProvider jss={jss}>
                <Admin loginPage={Login} theme={theme} authProvider={authProvider} dataProvider={dataProvider}
                       i18nProvider={i18nProvider} layout={CustomLayout}
                       title={"Bento Admin Panel"}
                       disableTelemetry>
                    <AuthorizedResource name={resource.user} list={UserList} show={UserShow} create={UserCreate}
                                        edit={UserEdit}/>
                    <AuthorizedResource name={resource.remoteConfig} list={ConfigList} show={ConfigShow}
                                        create={ConfigCreate} edit={ConfigEdit}/>
                    <AuthorizedResource name={resource.abTest} list={AbTestList} show={AbTestShow} create={AbTestCreate}
                                        edit={AbTestEdit}/>
                    <AuthorizedResource name={resource.feedback} list={FeedbackList} edit={FeedbackEdit}/>
                    <AuthorizedResource name={resource.faq} list={FaqList} show={FaqShow} create={FaqCreate}
                                        edit={FaqEdit}/>
                    <AuthorizedResource name={resource.faqCategory} list={FaqCategoryList} show={FaqCategoryShow}
                                        create={FaqCategoryCreate} edit={FaqCategoryEdit}/>
                    <AuthorizedResource name={resource.faqSubCategory} list={FaqSubcatList} show={FaqSubcatShow}
                                        create={FaqSubcatCreate} edit={FaqSubcatEdit}/>
                    <AuthorizedResource name={resource.supportTicketLabel} list={SupportTicketLabelList}
                                        show={SupportTicketLabelShow} create={SupportTicketLabelCreate}
                                        edit={SupportTicketLabelEdit}/>
                    <AuthorizedResource name={resource.supportTicket} list={SupportTicketList} show={SupportTicketShow}
                                        edit={SupportTicketEdit}/>
                    <AuthorizedResource name={resource.exercise} list={ExerciseList} show={ExerciseShow}
                                        edit={ExerciseEdit} create={ExerciseCreate}/>
                    <AuthorizedResource name={resource.diet} list={DietList} show={DietShow}/>
                    <AuthorizedResource name={resource.dietType} list={DietTypeList} show={DietTypeShow}
                                        edit={DietTypeEdit} create={DietTypeCreate}/>
                    <AuthorizedResource name={resource.dietTypeCategory} list={DietTypeCategoryList}
                                        show={DietTypeCategoryShow} edit={DietTypeCategoryEdit}
                                        create={DietTypeCategoryCreate}/>
                    <AuthorizedResource name={resource.specialType} list={SpecialTypeList} show={SpecialTypeShow}
                                        edit={SpecialTypeEdit} create={SpecialTypeCreate}/>
                    <AuthorizedResource name={resource.recipeCategory} list={RecipeCatsList} show={RecipeCatsShow}
                                        edit={RecipeCatsEdit} create={RecipeCatsCreate}/>
                    <AuthorizedResource name={resource.foodTag} list={FoodTagList} show={FoodTagShow} edit={FoodTagEdit}
                                        create={FoodTagCreate}/>
                    <AuthorizedResource name={resource.food} list={FoodList} show={FoodShow} edit={FoodEdit} create={FoodCreate}/>
                    <AuthorizedResource name={resource.package} list={PackageList} show={PackageShow} edit={PackageEdit}
                                        create={PackageCreate}/>
                    <AuthorizedResource name={resource.foodUnit} list={FoodUnitList} show={FoodUnitShow}
                                        edit={FoodUnitEdit} create={FoodUnitCreate}/>
                    <AuthorizedResource name={resource.hatedFood} list={HatedFoodList} show={HatedFoodShow}
                                        edit={HatedFoodEdit} create={HatedFoodCreate}/>
                    <AuthorizedResource name={resource.packageCalorie} list={PackageCalorieList}
                                        show={PackageCalorieShow} edit={PackageCalorieEdit}
                                        create={PackageCalorieCreate}/>
                    <AuthorizedResource name={resource.mealCategory} list={MealCategoryList} show={MealCategoryShow}
                                        edit={MealCategoryEdit} create={MealCategoryCreate}/>
                    <AuthorizedResource name={resource.disease} list={DiseaseList} show={DiseaseShow} edit={DiseaseEdit}
                                        create={DiseaseCreate}/>
                    <AuthorizedResource name={resource.recommendation} list={RecommendationList}
                                        show={RecommendationShow} edit={RecommendationEdit}
                                        create={RecommendationCreate}/>
                    <AuthorizedResource name={resource.recommendationCategory} list={RecommendationCategoryList}
                                        show={RecommendationCategoryShow} edit={RecommendationCategoryEdit}
                                        create={RecommendationCategoryCreate}/>
                    <AuthorizedResource name={resource.discount} list={DiscountList} show={DiscountShow}
                                        edit={DiscountEdit} create={DiscountCreate}/>
                    <AuthorizedResource name={resource.bulkDiscount} list={BulkdiscountList} show={BulkdiscountShow}
                                        edit={BulkdiscountEdit} create={BulkdiscountCreate}/>
                    <AuthorizedResource name={resource.promotion} list={PromotionList} show={PromotionShow}
                                        edit={PromotionEdit} create={PromotionCreate}/>
                    <AuthorizedResource name={resource.version} list={VersionList} show={VersionShow} edit={VersionEdit} create={VersionCreate}/>
                    <AuthorizedResource name={resource.tracker} list={TrackerList} show={TrackerShow} edit={TrackerEdit}
                                        create={TrackerCreate}/>
                    <AuthorizedResource name={resource.notification} list={NotificationList} show={NotificationShow} edit={NotificationEdit} create={NotificationCreate}/>
                    <AuthorizedResource name={resource.automaticNotification} list={AutomaticNotificationList} show={AutomaticNotificationShow} edit={AutomaticNotificationEdit} create={AutomaticNotificationCreate}/>
                    <AuthorizedResource name={resource.sms} list={SmsList} show={SmsShow} edit={SmsEdit} create={SmsCreate}/>
                    <AuthorizedResource name={resource.recipe} list={RecipeList} show={RecipeShow} create={RecipeCreate} edit={RecipeEdit}/>
                    <AuthorizedResource name={resource.hatedFood}/>
                    <AuthorizedResource name={resource.plan}/>
                    <AuthorizedResource name={resource.role}/>
                    <AuthorizedResource name={resource.invoice}/>
                    <AuthorizedResource name={resource.note} edit={NoteEdit}/>
                    <AuthorizedResource name={resource.log}/>
                    <AuthorizedResource name={resource.groceryCategory} list={GroceryCategoryList} show={GroceryCategoryShow} edit={GroceryCategoryEdit} create={GroceryCategoryCreate}/>
                    <AuthorizedResource name={resource.automaticSms} list={AutomaticSmsList} show={AutomaticSmsShow} edit={AutomaticSmsEdit} create={AutomaticSmsCreate}/>
                    <AuthorizedResource name={resource.costSource} list={CostSourceList} show={CostSourceShow} edit={CostSourceEdit} create={CostSourceCreate}/>
                    <AuthorizedResource name={resource.costLog} list={CostLogList} show={CostLogShow} edit={CostLogEdit} create={CostLogCreate}/>
                    <AuthorizedResource name={resource.questCategory} list={QuestCategoryList} show={QuestCategoryShow} edit={QuestCategoryEdit} create={QuestCategoryCreate}/>
                    <AuthorizedResource name={resource.quest} list={QuestList} show={QuestShow} edit={QuestEdit} create={QuestCreate}/>
                </Admin>
            </StylesProvider>
        </MuiPickersUtilsProvider>
    );
}

export default App;
