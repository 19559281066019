import React from 'react';
import {List,Datagrid,TextField,NumberField,DateField,BooleanField} from 'react-admin';
import {toCsv} from "../../utils/utils";
const FaqCategoryList = (props) => {
    return (
        <List {...props} exporter={exporter}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <NumberField source="order" />
                <DateField source="createdAt" locales={"fa-IR"} />
                <DateField source="updatedAt" locales={"fa-IR"} />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

const exporter = (faqCategories) => {
    const data = faqCategories.map(faqCategory => {
        return {
            name:faqCategory.name,
            order:faqCategory.order,
            createdAt:faqCategory.createdAt,
            updatedAt:faqCategory.updatedAt,
            deleted:faqCategory.deleted,
        };
    })
    toCsv(data,'faqCategories');
}

export default FaqCategoryList;