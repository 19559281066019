import React, {useMemo} from 'react';
import Table from "../../components/Table";
import useResource from "../../components/resource/useResource";
import resource from "../../config/resource";
import {Check, Close} from "@material-ui/icons";
import {useFormState} from "react-final-form";

const OverviewTable = () => {
    const {values} = useFormState();
    const caloriePercentError = 0.05;
    const type = useResource(resource.dietType)(values.types?.[0])?.[0];
    const foods = useResource(resource.food)(values.foods?.map(food => food?.foodId));
    const packageCalories = useResource(resource.packageCalorie)(values.calorieSizes?.map(calorieSize => calorieSize?.calorie))
    const columns = [
        {
            key: "caloriePerUnit",
            label: "Calorie/Unit"
        },
        {
            key: "foodName",
            label: "نام غذا"
        },
        {
            key: "foodUnit",
            label: "واحد"
        },
        ...packageCalories?.map(packageCalorie => ({
            key: packageCalorie.amount
        }))
    ];
    const mealRatio = useMemo(() => type?.mealRatioArray.filter(mealRatio => mealRatio.type === 'normal')[0].mealRatios.filter(mealRatio => mealRatio.meal === values.meal)[0]?.ratio,[type,values.meal]);
    const foodRows = useMemo(() => {
        let res = [];
        foods.forEach((food, foodIndex) => {
            let row = {carbohydratePerGram:food.foodFact.carbohydrateAmount/100,fatPerGram:food.foodFact.fatAmount/100,proteinPerGram:food.foodFact.proteinAmount/100,primaryFoodUnit:food.foodUnitRatioArray.filter(foodUnit => foodUnit.unitId===food.primaryFoodUnit._id)[0]|| {ratio:1,name:'گرم'},caloriePerUnit: food?.primaryFoodUnitCalorie, foodName: food?.name, foodUnit: food?.primaryFoodUnit.name}
            packageCalories.forEach((calorie, calorieIndex) => {
                row[calorie?.amount] = values.calorieSizes[calorieIndex]?.sizes?.[foodIndex]?.amount
            })
            res.push(row)
        })
        return res;
    },[foods,packageCalories,values]);
    const getTotalNutrition = (foodRowField,calorieStep) => {
        return foodRows.reduce((total,row) => total+=(row[calorieStep]*(row.primaryFoodUnit.ratio*row[foodRowField])),0).toFixed(1)
    }
    const getCaloriePercentage = (nutrition,calorieStep) => {
        const totalFatCalorie = getTotalNutrition("fatPerGram",calorieStep)*9;
        const totalCarbohydrateCalorie = getTotalNutrition("carbohydratePerGram",calorieStep)*4;
        const totalProteinCalorie = getTotalNutrition("proteinPerGram",calorieStep)*4;
        const totalCalorie = totalFatCalorie + totalProteinCalorie + totalCarbohydrateCalorie;
        const nutritionCalorie = nutrition==="protein" ? totalProteinCalorie : nutrition==="fat" ? totalFatCalorie : totalCarbohydrateCalorie;
        return (100*nutritionCalorie / totalCalorie).toFixed(1);
    }
    const totalProteinRow = useMemo(() =>{
        const row = {caloriePerUnit: "Total Protein"}
        packageCalories.forEach(packageCalorie => {row[packageCalorie.amount] = <div title={getCaloriePercentage("protein",packageCalorie.amount)+"%"}>{getTotalNutrition("proteinPerGram",packageCalorie.amount)}</div>})
        return row;
    },[packageCalories,foodRows]);
    const totalCarbohydrateAmountRow = useMemo(() =>{
        const row = {caloriePerUnit: "Total carbohydrate"}
        packageCalories.forEach(packageCalorie => {row[packageCalorie.amount] = <div title={getCaloriePercentage("carbohydrate",packageCalorie.amount)+"%"}>{getTotalNutrition("carbohydratePerGram",packageCalorie.amount)}</div>})
        return row;
    },[packageCalories,foodRows]);
    const totalFatRow = useMemo(() =>{
        const row = {caloriePerUnit: "Total Fat"};
        packageCalories.forEach(packageCalorie => {row[packageCalorie.amount] = <div title={getCaloriePercentage("fat",packageCalorie.amount)+"%"}>{getTotalNutrition("fatPerGram",packageCalorie.amount)}</div>})
        return row;
    },[packageCalories,foodRows]);
    const expectedCaloriesRow = useMemo(() => {
        const row = {caloriePerUnit: "Expected Calorie"};
        packageCalories.forEach(packageCalorie => {
            row[packageCalorie.amount] = packageCalorie?.amount * mealRatio / 100
        })
        return row;
    }, [packageCalories, mealRatio]);
    const totalCaloriesRow = useMemo(() =>{
        const row = {caloriePerUnit: "Total Calorie"}
        packageCalories.forEach(packageCalorie => {row[packageCalorie.amount] = foodRows.reduce((total,row) => total+=(row[packageCalorie.amount]*row.caloriePerUnit),0).toFixed(1)})
        return row;
    },[packageCalories,foodRows]);
    const validationRow = useMemo(() => {
        const row = {caloriePerUnit:"ValidationStatus"}
        packageCalories.forEach(packageCalorie => {
            row[packageCalorie.amount] = (Math.abs(totalCaloriesRow[packageCalorie.amount] - expectedCaloriesRow[packageCalorie.amount]) / expectedCaloriesRow[packageCalorie.amount]) <
            caloriePercentError
                ? <Check style={{color: 'green'}}/>
                : <Close style={{color: 'red'}}/>
        })
        return row;
    }, [totalCaloriesRow, expectedCaloriesRow,packageCalories]);
    return (
        <Table columns={columns} records={foodRows.concat(totalProteinRow).concat(totalFatRow).concat(totalCarbohydrateAmountRow).concat(totalCaloriesRow).concat(expectedCaloriesRow).concat(validationRow)}/>
    );
};

export default OverviewTable;