import React from 'react';
import {ArrayInput, BooleanInput, FormTab, NumberInput, SelectInput, TabbedForm, TextInput} from "react-admin";
import CustomFormIterator from "../../components/form/CustomFormIterator";
import choices from "../../config/choices";

const AutomaticSmsForm = (props) => {
    return (
        <TabbedForm {...props} redirect="list" >
            <FormTab label={"details"}>
                <BooleanInput source="deleted" />
                <BooleanInput source="paused" />
                <SelectInput source={"time"} choices={choices.automatedTimes}/>
                <TextInput source="description" fullWidth multiline/>
            </FormTab>
            <FormTab label={"filter"}>
                <SelectInput source="filter.type" choices={[
                    { id: "notPurchasedSinceRegisterDays", name: "تعداد روز بعد از ثبت نام که خرید نداشته است" },
                    { id: "subscriptionExpireInDays", name: "تعداد روز مانده تا اشتراک به پایان برسد" },
                    { id: "subscriptionExpiredSinceDays", name: "تعداد روز که اشتراک به پایان رسیده است" },
                    { id: "lastAppOpenedSinceDays", name: "تعداد روز گذشته از اخرین باز کردن برنامه" },
                ]} label="Type" resettable />
                <NumberInput source="filter.value" label={"value"}/>
            </FormTab>
            <FormTab label={"Data"}>
                <NumberInput source={"data.controlGroupWeight"}/>
                <ArrayInput source={"data.variants"}>
                    <CustomFormIterator>
                        <TextInput source={"variant"} label={"variant"}/>
                        <NumberInput source={"weight"} label={"weight"}/>
                        <TextInput source={"text"} label={"text"}/>
                    </CustomFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    );
};

export default AutomaticSmsForm;