import React from 'react';
import {
    ArrayField,
    BooleanField,
    Button, Datagrid,
    DateField,
    EditButton,
    NumberField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useMutation
} from "react-admin";
import useCustomNotify from "../../components/useCustomNotify";
import SendIcon from "@material-ui/icons/Send";
import AutomatedTimeField from "../../components/fields/AutomatedTimeField";

const AutomaticSmsShow = (props) => {

    return (
        <Show {...props} actions={<AutomaticSmsActions/>}>
            <TabbedShowLayout {...props}>
                <Tab label={"details"}>
                    <BooleanField addLabel source="deleted"/>
                    <BooleanField addLabel source="paused"/>
                    <AutomatedTimeField source="time"/>
                    <DateField source="createdAt"/>
                    <DateField source="updatedAt"/>
                    <NumberField source="sentCount" emptyText={"-"}/>
                    <NumberField source="openCount" emptyText={"-"}/>
                    <TextField source="description" fullWidth emptyText={"-"}/>
                </Tab>
                <Tab label={"data"}>
                    <NumberField source="data.controlGroupWeight" label="controlGroupWeight" emptyText={"-"}/>
                    <ArrayField source={"data.variants"}>
                        <Datagrid>
                            <TextField source={"variant"}/>
                            <NumberField source={"weight"}/>
                            <TextField source={"text"}/>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const AutomaticSmsActions = ({data:record,...props}) => {
    const notify = useCustomNotify();
    const [sendSms, {loading}] = useMutation();
    const onSendSms = (id) => {
        sendSms({
            type: 'sendSms',
            payload: {id}
        }, {
            onSuccess: notify
        })
    }
    return (
        <TopToolbar>
            {record?.status !== 'sent' &&
            <Button label={"ارسال"} disabled={loading} onClick={() => onSendSms(record._id)}
                    startIcon={<SendIcon/>}/>}
            <EditButton basePath={props.basePath} record={record}/>
        </TopToolbar>
    )
}

export default AutomaticSmsShow;