import React from 'react';
import {Edit, required, SimpleForm, TextInput} from "react-admin";

const CostSourceEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect={"list"}>
                <TextInput source={"title"} validate={[required()]}/>
            </SimpleForm>
        </Edit>
    );
};

export default CostSourceEdit;