import React from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';
import useStyles from "./styles";

const FoodDetails = (props) => {
    const classes = useStyles();
    return (
        <Show {...props}>
            <SimpleShowLayout className={classes.card}>
                <TextField source="foodFact.calorieAmount" label="Calorie Amount"/>
                <TextField source="foodFact.proteinAmount" label="Protein Amount"/>
                <TextField source="foodFact.fatAmount" label="Fat Amount"/>
                <TextField
                    source="foodFact.carbohydrateAmount"
                    label="Carbohydrate Amount"
                />
                <TextField source="foodFact.sugarAmount" label="Sugar Amount"/>
                <TextField source="foodFact.fiberAmount" label="Fiber Amount"/>
                <TextField source="foodFact.sodiumAmount" label="Sodium Amount"/>
                <TextField source="foodFact.potassiumAmount" label="Potassium Amount"/>
                <TextField source="foodFact.calciumAmount" label="Calcium Amount"/>
                <TextField source="foodFact.magnesiumAmount" label="Magnesium Amount"/>
                <TextField source="foodFact.ironAmount" label="Iron Amount"/>
                <TextField
                    source="foodFact.cholesterolAmount"
                    label="Cholesterol Amount"
                />
                <TextField source="foodFact.phosphorAmount" label="Phosphor Amount"/>
                <TextField
                    source="foodFact.saturatedFatAmount"
                    label="Saturated FatAmount"
                />
                <TextField
                    source="foodFact.polyunsaturatedFatAmount"
                    label="Polyunsaturated Fat Amount"
                />
                <TextField
                    source="foodFact.monounsaturatedFatAmount"
                    label="Monounsaturated Fat Amount"
                />
                <TextField source="foodFact.transFatAmount" label="Trans Fat Amount"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default FoodDetails;