import React from 'react';
import {
    BooleanInput,
    Create,
    NumberInput,
    ReferenceInput,
    required, SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";
import resource from "../../config/resource";

const PromotionCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="title" label="عنوان" validate={[required()]} />
                <DatePicker
                    source="startDate"
                    label="تاریخ شروع"
                    validate={[required()]}
                />
                <DatePicker
                    source="endDate"
                    label="تاریخ پایان"
                />
                <NumberInput source="percent" label="درصد" validate={[required()]} />
                <ReferenceInput source="plan" reference={resource.plan}>
                    <SelectInput optionText={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`} validate={[required()]} />
                </ReferenceInput>
                <SelectInput allowEmpty source={"market"} choices={[
                    {id: "play", name: "Google Play"},
                    {id: "bazaar", name: "Cafe Bazaar"},
                    {id: "myket", name: "Myket"},
                    {id: "pwa", name: "PWA"},
                    {id: "ios", name: "IOS"},
                    {id: "sam", name: "sam"},
                ]}/>
                <BooleanInput source="deleted" label="پاک شده" />
            </SimpleForm>
        </Create>
    );
};

export default PromotionCreate;