import React from 'react';
import {DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField} from "react-admin";
import resource from "../../config/resource";

const CostLogShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ReferenceField reference={resource.costSource} source={"cost"}>
                    <TextField source={"title"}/>
                </ReferenceField>
                <TextField source={"description"}/>
                <DateField source={"date"}/>
                <NumberField source={"amount"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default CostLogShow;