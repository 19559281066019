import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    TextField,
    SelectInput,
    Button, useMutation, ReferenceField, FunctionField
} from 'react-admin';
import {toCsv} from "../../utils/utils";
import resource from "../../config/resource";

const BulkdiscountList = (props) => {
    return (
        <List filters={<BulkDiscountFilter/>} {...props}>
            <Datagrid rowClick={"show"}>
                <DateField emptyText={"-"} source="expirationDate" locales="fa-IR" label="تاریخ انقضا"/>
                <BooleanField addLabel source="deleted" label="پاک شده"/>
                <DateField source="createdAt" locales="fa-IR" label="تاریخ ساخت"/>
                <DateField source="updatedAt" locales="fa-IR" label="آخرین بروزرسانی"/>
                <NumberField source="percent" label="درصد" locales="fa-IR"/>
                <NumberField source="count" label="تعداد" locales="fa-IR"/>
                <NumberField source="consumed" label="مصرف شده" locales="fa-IR"/>
                <TextField source="description" label="توضیح"/>
                <TextField source="type" label="نوع"/>
                <ReferenceField label="نوع اشتراک" source="plan" reference={resource.plan} linkType={false}>
                    <FunctionField
                        label="plan"
                        render={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`}
                    />
                </ReferenceField>
                <Export label="Export"/>
            </Datagrid>
        </List>
    );
};

const Export = ({record}) => {
    const [getDiscounts] = useMutation({
        resource: "discount",
        type: "getList",
        payload: {
            bulkDiscount: record.id,
            pagination: {
                page: 1,
                perPage: 999999
            }
        }
    },{
        onSuccess:res => toCsv(res.data.map(row => ({code:row.code,consumed:String(row.consumed)})), 'Bulk_Discount',['code', 'consumed'])
    })
    return (
        <Button label={"Export"} onClick={(e) =>{e.stopPropagation();getDiscounts();}} variant="contained" color="primary"/>
    );
};

const BulkDiscountFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="type"
            label="نوع"
            alwaysOn
            choices={[
                {id: "bulk", name: "Normal"},
                {id: "gift", name: "Gift"},
            ]}
        />
    </Filter>
);
export default BulkdiscountList;