import React from 'react';
import {Create, NumberInput, SimpleForm, TextInput} from 'react-admin';

const ExerciseCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" />
                <NumberInput source="met" />
            </SimpleForm>
        </Create>
    );
};

export default ExerciseCreate;