import React from 'react';
import {
    BooleanField, Button,
    DateField, EditButton,
    NumberField,
    Show,
    TabbedShowLayout, Tab,
    TextField, TopToolbar, useMutation
} from 'react-admin';
import TextArrayField from "../../components/fields/TextArrrayField";
import useCustomNotify from "../../components/useCustomNotify";
import ConditionalField from "../../components/fields/ConditionalField";
import SendIcon from "@material-ui/icons/Send";

const SmsShow = (props) => {

    return (
        <Show {...props} actions={<SmsActions/>}>
            <TabbedShowLayout>
                <Tab label={"details"}>
                    <BooleanField addLabel source="deleted"/>
                    <BooleanField addLabel source="scheduleSend"/>
                    <ConditionalField condition={record => record.scheduleSend}>
                        <DateField source="scheduleSendDateTime" locales={"fa-IR"}/>
                    </ConditionalField>
                    <TextField source="status"/>
                    <DateField source="createdAt" locales={"fa-IR"}/>
                    <DateField source="updatedAt" locales={"fa-IR"}/>
                    <NumberField emptyText={"-"} source="segmentCount"/>
                    <NumberField emptyText={"-"} source="sentCount"/>
                    <NumberField emptyText={"-"} source="openCount"/>
                    <TextField emptyText={"-"} source="description" fullWidth/>
                </Tab>
                <Tab label={"filters"}>
                    <DateField emptyText={"-"} source="filters.registerStartDate" label="Register Start Date"/>
                    <DateField emptyText={"-"} source="filters.registerEndDate" label="Register End Date"/>
                    <TextField emptyText={"-"} source="filters.sex" label="Sex"/>
                    <TextArrayField emptyText={"-"} source={"filters.market"}/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasPurchased" label="Has Purchased"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasWeightLog" label="Has Weight Log"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasWaterLog" label="Has Water Log"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasFoodLog" label="Has Food Log"/>
                    <BooleanField emptyText={"-"} addLabel source="filters.hasSubscription" label="Has Subscription"/>
                </Tab>
                <Tab label={"data"}>
                    <TextField source="data.text" label="Body" fullWidth/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const SmsActions = ({data:record, ...props}) => {
    const notify = useCustomNotify();
    const [send, {loading}] = useMutation();
    const onSendSms = (id) => {
        send({
            type: 'sendSms',
            payload: {id}
        }, {
            onSuccess: notify,
            onFailure: notify
        })
    }
    return (
        <TopToolbar>
            {record?.status !== 'sent' &&
            <Button label={"ارسال"} disabled={loading} onClick={() => onSendSms(record._id)}
                    startIcon={<SendIcon/>}/>}
            <EditButton basePath={props.basePath} record={record}/>
        </TopToolbar>
    )
}

export default SmsShow;