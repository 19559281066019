import React from 'react';
import {Create,SimpleForm,BooleanInput,TextInput,SelectInput,ReferenceInput,NumberInput,required} from 'react-admin';
import resource from "../../config/resource";
const FaqCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <BooleanInput source="deleted" />
                <TextInput fullWidth source="question" validate={[required()]} />
                <TextInput fullWidth source="answer" validate={[required()]} />
                <ReferenceInput
                    source="subcategory"
                    reference={resource.faqSubCategory}
                    label="Subcategory"
                    validate={[required()]}
                >
                    <SelectInput
                        optionText="name"
                    />
                </ReferenceInput>
                <NumberInput source="order" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};

export default FaqCreate;