import {httpClient} from "../config/client";
import Service from "./Service";
import api from "../config/api";
import resource from "../config/resource";
import {toEnglishNumber} from "../utils/utils";


class UserService extends Service {
    getLoginCode({username}) {
        return httpClient({URL: '/users', method: 'POST', data: {username:toEnglishNumber(username), market: 'panel'}})
    }

    login({username, code}) {
        let base64 = window.btoa(`${toEnglishNumber(username)}:${code}`)
        return httpClient({
            URL: '/users/login', data: null, method: 'POST', headers: {
                'content-type': "application/x-www-form-urlencoded",
                Authorization: `Basic ${base64}`,
                Accept: 'application/json'
            }
        })
    }

    refreshToken() {
        return httpClient({
            URL: "/users/refresh",
            method: "POST",
            data: {refreshToken: localStorage.getItem("refreshToken")}
        })
    }

    getCurrentUser() {
        return httpClient({URL: "/users/user"}).then(res => res.data)
    }

    update(params) {
        const {subscriptionMonths} = params.data;
        if (subscriptionMonths)
            api[resource.subscription].create({data: {userId: params.id, months: Math.floor(subscriptionMonths)}}).then(() => {
                return super.update(params);
            })
        return super.update(params)
    }
}

export default UserService;