import { useTranslate, FunctionField } from 'react-admin';
import get from 'lodash/get';
const TranslateField = ({source})  => {
    const translate = useTranslate();
    return (
        <FunctionField source={source}
                       render={ (record, source) => translate(`${source}.${get(record, source)}`,{_:get(record, source)}) }
        />
    )
};

TranslateField.defaultProps={
    addLabel:true
}
export default TranslateField;