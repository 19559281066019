import React from 'react';
import {Edit, SimpleForm, TextInput} from 'react-admin';

const TrackerEdit = (props) => {
    return (
        <Edit {...props} >
            <SimpleForm>
                <TextInput source="source" />
                <TextInput source="medium" />
                <TextInput source="trackerLink" />
                <TextInput source="description" />
            </SimpleForm>
        </Edit>
    );
};

export default TrackerEdit;