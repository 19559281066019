import React, {useState} from 'react';
import {
    Edit,
} from 'react-admin';
import Aside from "./Aside";
import SmsForm from "./SmsForm";
const SmsEdit = (props) => {
    const [values, setValues] = useState();
    return (
        <Edit {...props} aside={<Aside values={values} />} >
            <SmsForm setValues={setValues}/>
        </Edit>
    );
};

export default SmsEdit;