import React from 'react';
import {Edit} from 'react-admin';
import VersionForm from "./VersionForm";

const VersionEdit = (props) => {
    return (
        <Edit {...props}>
            <VersionForm/>
        </Edit>
    );
};

export default VersionEdit;