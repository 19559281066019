import React from 'react';
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    DateField,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    Show,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import PassedDayField from "../../components/fields/PassedDayField";
import GoalField from "../../components/fields/GoalField";
import TranslateField from "../../components/fields/TranslateField";
import resource from "../../config/resource";
import ConditionalField from "../../components/fields/ConditionalField";

const DietShow = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="اطلاعات کلی رژیم">
                    <ReferenceField source="user" reference={resource.user} label="نام">
                        <TextField source="name"/>
                    </ReferenceField>
                    <DateField locales="fa-IR" source="createdAt" label="زمان ساخت رژیم"/>
                    <DateField source="startDate" label="تاریخ شروع رژیم" locales="fa-IR"/>
                    <PassedDayField source="createdAt" label="روز رژیم"/>
                    <GoalField source="goal" label="هدف"/>
                    <TranslateField source="difficulty" label="درجه سختی"/>
                    <NumberField source="calorie" label="کالری" locales="fa-IR"/>
                    <ConditionalField condition={record => record.goal === 'pregnancy'}>
                        <DateField emptyText={"-"} source="pregnancyDate" label="تاریخ شروع بارداری" locales={"fa-IR"}/>
                    </ConditionalField>
                    <ConditionalField condition={record => record.goal === 'breastfeeding'}>
                        <DateField emptyText={"-"} source="breastFeedingDate" label="تاریخ شروع شیردهی"
                                   locales={"fa-IR"}/>
                    </ConditionalField>
                    <ReferenceArrayField
                        source="specialTypes"
                        reference={resource.specialType}
                        label="رژیم‌های خاص"
                    >
                        <SingleFieldList linkType={false}>
                            <TextField className="packageFoods" source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <BooleanField addLabel source="isCanceled" label="لغو شده"/>
                    <DateField source="cancelDate" locales="fa-IR" label="زمان لغو"/>
                    <BooleanField addLabel source="deleted" label="پاک شده"/>
                </Tab>
                <Tab label={"مشخصات کاربر"}>
                    <NumberField source="startWeight" label="وزن شروع رژیم"/>
                    <ReferenceArrayField
                        source="hatedFoods"
                        reference={resource.hatedFood}
                        label="غذاهای حساسیت‌زا"
                    >
                        <SingleFieldList>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField
                        source="diseases"
                        reference={resource.disease}
                        label="بیماری‌ها"
                    >
                        <SingleFieldList>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <TranslateField source="activity" label="سطح فعالیت"/>
                </Tab>
                <Tab label={"برنامه غذایی"}>
                    <ArrayField source={"days"} label={""}>
                        <Datagrid>
                            <NumberField source="day" label="روز"/>
                            <BooleanField addLabel source="isCheatDay" label="روز آزاد"/>
                            <ArrayField source={"meals"} label={"وعده ها"}>
                                <Datagrid>
                                    <TranslateField source="meal" label="وعده"/>
                                    <TranslateField source="status" label="وضعیت"/>
                                    <ArrayField source={"foods"} label={"غذا ها"}>
                                        <Datagrid>
                                            <TextField source="food.name" label="نام"/>
                                            <NumberField source="amount" label="مقدار"/>
                                            <TextField source="food.primaryFoodUnit.name" label="واحد"/>
                                        </Datagrid>
                                    </ArrayField>
                                </Datagrid>
                            </ArrayField>
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default DietShow;