import React from 'react';
import {Edit,SimpleForm,TextInput} from 'react-admin';
const ConfigEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="key" />
                <TextInput source="value" />
            </SimpleForm>
        </Edit>
    );
};

export default ConfigEdit;