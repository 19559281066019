import React from 'react';
import {BooleanField, Datagrid, DateField, Filter, List, BooleanInput, SelectInput, TextField} from 'react-admin';

const AutomaticNotificationList = (props) => {
    return (
        <List {...props} filters={<AutomatedNotificationFilter />}>
            <Datagrid rowClick="show">
                <TextField source="description" fullWidth emptyText={"-"}/>
                <TextField addLabel source="sentCount" emptyText={"-"}/>
                <TextField addLabel source="openCount" emptyText={"-"}/>
                <DateField source="createdAt"/>
                <BooleanField addLabel source="deleted"/>
                <BooleanField addLabel source="paused"/>
            </Datagrid>
        </List>
    );
};

const AutomatedNotificationFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="status"
            alwaysOn
            choices={[
                { id: "processing", name: "Processing" },
                { id: "readyToSend", name: "Ready To Send" },
                { id: "sending", name: "Sending" },
                { id: "sent", name: "Sent" },
            ]}
        />
        <BooleanInput source={"deleted"}/>
        <BooleanInput source={"paused"}/>
    </Filter>
);

export default AutomaticNotificationList;