import React from 'react';
import {BooleanField, DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';
import resource from "../../config/resource";

const FaqSubcatShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField addLabel source="deleted" />
                <TextField source="name" />
                <ReferenceField source="category" reference={resource.faqCategory} >
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="order" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default FaqSubcatShow;