import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

const SupportTicketLabelCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="text" />
            </SimpleForm>
        </Create>
    );
};

export default SupportTicketLabelCreate;