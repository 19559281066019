import React from 'react';
import {
    Create,
    FormDataConsumer, maxValue, minValue,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";
import resource from "../../config/resource";

const DiscountCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="code" label="کد" validate={required()}/>
                <NumberInput source="limit" label="محدودیت تعداد"/>
                <DatePicker
                    source="expirationDate"
                    label="تاریخ انقضا"
                />
                <TextInput source="description" label="توضیح"/>
                <SelectInput
                    source="type"
                    label="نوع"
                    choices={[
                        {id: "normal", name: "Normal"},
                        {id: "automated", name: "Automated"},
                        {id: "gift", name: "Gift"},
                    ]}
                    defaultValue="normal"
                />
                <FormDataConsumer>
                    {({formData}) =>
                        formData.type !== "gift" &&
                        <NumberInput source="percent" label="درصد" validate={[required(), maxValue(99), minValue(1)]}/>
                    }
                </FormDataConsumer>
                <ReferenceInput reference={resource.plan} source={"plan"}>
                    <SelectInput optionText={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`} resettable/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default DiscountCreate;