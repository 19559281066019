import React, {useState} from 'react';
import {useLogin, Notification, useMutation} from 'react-admin';
import {
    Avatar, Box,
    Button,
    Container,
    Paper,
    TextField, ThemeProvider,
    Typography
} from "@material-ui/core";
import Logo from '../../assets/images/bento_logo.webp';

import useStyles from "./styles";
import {phoneNumberValidation} from "../../utils/validation";
import {theme} from "../../config/theme";
import useCustomNotify from "../../components/useCustomNotify";

const Login = () => {
    const [getLoginCode, {loading}] = useMutation();
    const onGetLoginCode = (phoneNumber) => {
        getLoginCode({
            type: 'getLoginCode',
            payload: {username: phoneNumber}
        },{
            onSuccess: res => {
                notify(res);
                setState('code');
            },
            onFailure:err => {
                notify(err)
                if(err.message==='کد ورود ارسال شده است')
                    setState('code');
            }
        })
    }
    const notify = useCustomNotify();
    const classes = useStyles();
    const login = useLogin();
    const [state, setState] = useState('phone');
    const [values, setValues] = useState({
        phone: {
            value: '',
            error: ''
        },
        code: {
            value: '',
            error: ''
        }
    })

    function onSubmit(e) {
        e.preventDefault();
        if (state === 'phone') {
            const phoneNumberErrorMessage = phoneNumberValidation(values.phone.value)
            if (!phoneNumberErrorMessage) {
                onGetLoginCode(String(values.phone.value))
            } else
                setValues({...values, phone: {...values.phone, error: phoneNumberErrorMessage}})
        } else {
            login({
                username: String(values.phone.value),
                code: values.code.value
            }).catch(err => notify(err.message, "error"));
        }
    }

    function onChange(e) {
        setValues({...values, [e.target.name]: {value: e.target.value}})
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar} alt="Bento Logo" src={Logo}/>
                    <Typography component="h1" variant="h5">ورود</Typography>
                    <form className={classes.form} onSubmit={onSubmit}>
                        {state === 'phone' && <TextField
                            variant="filled"
                            margin="normal"
                            required
                            error={!!values.phone.error}
                            helperText={values.phone.error}
                            fullWidth
                            id="phone"
                            label="شماره"
                            name="phone"
                            autoFocus
                            onChange={onChange}
                        />}
                        {state === 'code' && <>
                            <TextField
                                variant="filled"
                                margin="normal"
                                required
                                fullWidth
                                name="code"
                                label="کد"
                                type="number"
                                id="code"
                                onChange={onChange}
                            />
                            <Box align={"center"}>
                                <Button color={"primary"} onClick={() => setState('phone')}>اصلاح شماره</Button>
                            </Box>
                        </>}
                        <Button
                            disabled={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {state === 'phone' ? 'دریافت کد' : 'ورود'}
                        </Button>
                    </form>
                </Paper><Notification/>
            </Container>
        </ThemeProvider>

    )
};

export default Login;