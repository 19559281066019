import React from 'react';
import {BooleanInput, Edit, NumberInput, SimpleForm, TextInput} from 'react-admin';

const ExerciseEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <BooleanInput source="deleted" />
                <TextInput source="name" />
                <NumberInput source="met" />
            </SimpleForm>
        </Edit>
    );
};

export default ExerciseEdit;