import React from 'react';
import SendIcon from "@material-ui/icons/Send";
import {Button, required, SelectInput, TextInput, useMutation} from "react-admin";
import useCustomNotify from "../../components/useCustomNotify";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomForm from "../../components/form/CustomForm";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useModal from "../../components/useModal";
import Upload from "../../components/inputs/Upload";

const SendNotificationButton = ({record}) => {

    const {open,onClose,onOpen} = useModal();

    const notify = useCustomNotify();

    const [sendNotification, {loading}] = useMutation();

    const onSendNotification = (values) => {
        sendNotification({
            type: 'sendTestNotification',
            payload: values
        }, {
            onSuccess: res => {
                notify(res);
                onClose();
            },
            onFailure: notify
        })
    }
    return (
        <>
            <Button label={"notification"} disabled={loading} onClick={onOpen}
                    startIcon={<SendIcon/>}/>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">notification</DialogTitle>

                <CustomForm initialValues={{testUsers: [record?._id]}} save={onSendNotification} saving={loading} toolbar={null}>
                    {(_,form) => (
                        <>
                            <DialogContent>
                                <TextInput source="data.title" label="Title"/>
                                <TextInput source="data.body" label="Body" fullWidth multiline validate={[required()]}/>
                                <TextInput source="data.actionUrl" label="Action URL"/>
                                <SelectInput source="data.channel" validate={[required()]} choices={[
                                    {id: "bento", name: "Bento"},
                                    {id: "recipe", name: "Recipe"},
                                ]} label="Channel" resettable/>
                                <Upload type={"image"} source={"data.image"} segment={"notification"} label={"image"}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose} color="primary" label={"لغو"}/>
                                <Button onClick={form.submit} color="primary" label={"ارسال"}/>
                            </DialogActions>
                        </>
                    )}
                </CustomForm>
            </Dialog>
        </>
    );
};

export default SendNotificationButton;