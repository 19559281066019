import React from 'react';
import {ArrayField, Datagrid, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';
import resource from "../../config/resource";

const QuestShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <ReferenceField reference={resource.questCategory} source={"category"}>
                    <TextField source={"title"}/>
                </ReferenceField>
                <TextField source={"title"}/>
                <ArrayField source={"questLevels"}>
                    <Datagrid>
                        <TextField source={"level"}/>
                        <TextField source={"prize"}/>
                        <TextField source={"text"}/>
                        <TextField source={"APIUrl"} label={"Url"}/>
                        <TextField source={"APIMethod"} label={"Method"}/>
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default QuestShow;