import React from 'react';
import {Create,SimpleForm,TextInput} from 'react-admin';
const ConfigCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show" >
                <TextInput source="key" />
                <TextInput source="value" />
            </SimpleForm>
        </Create>
    );
};

export default ConfigCreate;