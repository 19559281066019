import React from 'react';
import {BooleanField, ReferenceArrayField, Show, SimpleShowLayout, SingleFieldList, TextField} from 'react-admin';
import resource from "../../config/resource";

const SpecialTypeShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField addLabel source="deleted" />
                <TextField source="name" />
                <ReferenceArrayField source="suitableTags" reference={resource.foodTag} >
                    <SingleFieldList linkType={false}>
                        <TextField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="notSuitableTags" reference={resource.foodTag} >
                    <SingleFieldList linkType={false}>
                        <TextField className="packageFoods" source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default SpecialTypeShow;