import React from 'react';
import {Edit, NumberInput, ReferenceInput, SimpleForm, TextInput,SelectInput} from "react-admin";
import resource from "../../config/resource";
import DatePicker from "../../components/inputs/DatePicker";

const CostLogEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect={"list"}>
                <ReferenceInput reference={resource.costSource} source={"cost"}>
                    <SelectInput optionText={"title"}/>
                </ReferenceInput>
                <TextInput source={"description"}/>
                <DatePicker source={"date"}/>
                <NumberInput source={"amount"}/>
            </SimpleForm>
        </Edit>
    );
};

export default CostLogEdit;