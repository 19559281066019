import React from 'react';
import {BooleanField, NumberField, Show, SimpleShowLayout, TextField} from 'react-admin';

const ExerciseShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" />
                <NumberField source="met" />
                <BooleanField addLabel source="deleted" />
            </SimpleShowLayout>
        </Show>
    );
};

export default ExerciseShow;