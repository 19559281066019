import React from 'react';
import {List, Datagrid,ReferenceField,TextField,ArrayField} from 'react-admin';
import resource from "../../config/resource";

const QuestList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick={"show"}>
                <ReferenceField reference={resource.questCategory} source={"category"}>
                    <TextField source={"title"}/>
                </ReferenceField>
                <TextField source={"title"}/>
                <ArrayField source={"questLevels"}>
                    <Datagrid>
                        <TextField source={"level"}/>
                        <TextField source={"prize"}/>
                        <TextField source={"text"}/>
                        <TextField source={"APIUrl"} label={"Url"}/>
                        <TextField source={"APIMethod"} label={"Method"}/>
                    </Datagrid>
                </ArrayField>
            </Datagrid>
        </List>
    );
};

export default QuestList;