import Service from "./Service";

class BulkDiscountService extends Service{
    transformRequest(req) {
        return {
            ...req,
            expirationDate:new Date(req.expirationDate).getTime()
        }
    }
}
export default BulkDiscountService;