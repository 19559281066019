import React from 'react';
import {BooleanField, ReferenceArrayField, Show, SimpleShowLayout, SingleFieldList, TextField} from 'react-admin';
import resource from "../../config/resource";

const RecommendationShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField
                    source="text"
                />
                <BooleanField addLabel source="deleted" />
                <ReferenceArrayField label="Categories" source="category" reference={resource.recommendationCategory}>
                    <SingleFieldList>
                        <TextField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default RecommendationShow;