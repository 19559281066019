import React from 'react';
import {SimpleForm,ReferenceInput,SelectInput,SimpleFormIterator,NumberInput,TextInput,ArrayInput} from 'react-admin';
import resource from "../../config/resource";
import choices from "../../config/choices";

const QuestForm = (props) => {
    return (
        <SimpleForm {...props} redirect={"list"}>
            <ReferenceInput reference={resource.questCategory} filter={{deleted:false}} source={"category"}>
                <SelectInput optionText={"title"}/>
            </ReferenceInput>
            <TextInput source={"title"}/>
            <ArrayInput source={"questLevels"}>
                <SimpleFormIterator>
                    <NumberInput source={"level"} label={"level"}/>
                    <NumberInput source={"prize"} label={"prize"}/>
                    <TextInput source={"text"} label={"text"}/>
                    <TextInput source={"APIUrl"} label={"url"}/>
                    <SelectInput source={"APIMethod"} label={"method"} choices={choices.apiMethods}/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    );
};

export default QuestForm;