import React from 'react';
import {Show, SimpleShowLayout, TextField} from "react-admin";

const CostSourceShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source={"title"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default CostSourceShow;