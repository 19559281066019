import React from 'react';
import {Create} from 'react-admin';
import QuestForm from "./QuestForm";

const QuestCreate = (props) => {
    return (
        <Create {...props}>
            <QuestForm/>
        </Create>
    );
};

export default QuestCreate;