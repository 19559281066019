import React from 'react';
import {useInput, useTranslate} from "react-admin";
import {Box} from "@material-ui/core";
import {DateTimePicker as MuiDateTimePicker} from "@material-ui/pickers";

const DateTimePicker = ({source,...props}) => {
    const translate = useTranslate();
    const {
        input: {name, onChange, value, ...rest},
        meta: {touched, error},
        isRequired
    } = useInput({source,...props});
    return (
        <Box mb={!(touched && error) && 3}>
            <MuiDateTimePicker
                label={props.label || source}
                clearable
                autoOk={true}
                okLabel="تأیید"
                cancelLabel="لغو"
                clearLabel="پاک کردن"
                format={'jYYYY/jMM/jDD - HH:mm'}
                emptyLabel={"-"}
                value={value || null}
                onChange={onChange}
                inputVariant={props.variant || "filled"}
                inputProps={rest}
                error={!!(touched && error)}
                helperText={touched && error && translate(error)}
                required={isRequired}
                className={props.className}
            />
        </Box>
    )
};

export default DateTimePicker;