import React from 'react';
import {Edit} from "react-admin";
import AutomaticSmsForm from "./AutomaticSmsForm";

const AutomaticSmsEdit = (props) => {
    return (
        <Edit {...props}>
           <AutomaticSmsForm/>
        </Edit>
    );
};

export default AutomaticSmsEdit;