import React from 'react';
import {BooleanField, Datagrid, List, NumberField, TextField} from 'react-admin';

const ExerciseList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <NumberField source="met" />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

export default ExerciseList;