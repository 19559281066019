import React from 'react';
import {Edit,SimpleForm,ReferenceField,TextField,BooleanInput} from 'react-admin';
import resource from "../../config/resource";
const FeedbackEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <ReferenceField source="user._id" reference={resource.user} label="نام">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="text" label="متن" />
                <TextField source="type" label="نوع" />
                <BooleanInput source="checked" label="چک شده" />
                <BooleanInput source="deleted" label="پاک شده" />
            </SimpleForm>
        </Edit>
    );
};

export default FeedbackEdit;