import React, {useState} from 'react';
import {
    Create,
} from 'react-admin';
import Aside from "./Aside";
import NotificationForm from "./NotificationForm";

const NotificationCreate = (props) => {
    const [formValues, setFormValues] = useState({})
    return (
        <Create {...props} aside={<Aside values={formValues}/>}>
            <NotificationForm setFormValues={setFormValues}/>
        </Create>
    );
};

export default NotificationCreate;