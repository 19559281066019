import Service from "./Service";
import {httpClient} from "../config/client";
class NotificationService extends Service{
    sendTest(params) {
        return httpClient({URL:`${this.URL}/sendtest`,method:'POST',data: params})
    }
    send(params){
        return httpClient({URL:`${this.URL}/send/${params.id}`,method:'PUT'})
    }
}
export default NotificationService;