import React from 'react';
import {FunctionField} from 'react-admin';
import choices from "../../config/choices";

const AutomatedTimeField = ({source,...props}) => {
    return (
        <FunctionField source={source} render={ (record, source) => choices.automatedTimes.filter(time => record[source]===time.id)[0]?.name}/>
    );
};

AutomatedTimeField.defaultProps={
    addLabel:true
}

export default AutomatedTimeField;