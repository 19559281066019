import React from 'react';
import {BooleanField, Datagrid, DateField, Filter, List, SelectInput, TextField} from 'react-admin';

const NotificationList = (props) => {
    return (
        <List {...props} filters={<NotificationFilter />}>
            <Datagrid rowClick="show">
                <TextField source="data.body" label="Body" />
                <TextField source="status" />
                <DateField source="createdAt" locales={"fa-IR"} />
                <TextField source="segmentCount" />
                <TextField source="sentCount" />
                <TextField source="openCount" />
                <TextField source="abTestWeight" />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};
const NotificationFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="status"
            alwaysOn
            choices={[
                { id: "processing", name: "Processing" },
                { id: "readyToSend", name: "Ready To Send" },
                { id: "sending", name: "Sending" },
                { id: "sent", name: "Sent" },
            ]}
        />
    </Filter>
);
export default NotificationList;