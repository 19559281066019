import React from 'react';
import {FunctionField} from 'react-admin';
import {toToman} from "../../utils/utils";

const PriceField = ({source}) => {
    return <FunctionField source={source} render={(record, source) => toToman(record[source])}/>;
};
PriceField.defaultProps = {
    addLabel:true
}

export default PriceField;