import React from 'react';
import {BooleanField, DateField, Show, SimpleShowLayout, TextField} from 'react-admin';
import WebsiteMediumLink from "../../components/fields/WebsiteMediumLink";

const TrackerShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="source" />
                <TextField source="medium" />
                <WebsiteMediumLink source="medium" label="Website Link"/>
                <TextField source="trackerLink" />
                <TextField emptyText={"-"} source="description" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <BooleanField addLabel source="deleted" />
            </SimpleShowLayout>
        </Show>
    );
};

export default TrackerShow;