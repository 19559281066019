import React from 'react';
import {
    BooleanInput,
    CheckboxGroupInput,
    Edit, SelectInput, NumberInput,
    ReferenceArrayInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import resource from "../../config/resource";
import Upload from "../../components/inputs/Upload";

const RecipeCatsEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm
                redirect="show"
            >
                <TextInput source="name" />
                <Upload segment={"recipeCategory"} type={"image"} source={"selectImage"}/>
                <Upload segment={"recipeCategory"} type={"image"} source={"deselectImage"}/>
                <SelectInput source={"ratio"} choices={[{id:'1:1',name:'1:1'},{id:"16:9",name:"16:9"}]}/>
                <SelectInput source={"type"} choices={ [{id:'recent',name:'recent'}, {id:'popular',name:'Popular'}, {id:'fast',name:'fast'}, {id:'all',name:'All'}, {id:'kitchen',name:'Kitchen'}]}/>
                <NumberInput source={"horizontalOrder"}/>
                <NumberInput source={"verticalOrder"}/>
                <ReferenceArrayInput
                    source="tags"
                    reference={resource.foodTag}
                    label="Tags"
                >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
                <BooleanInput source="deleted" />
            </SimpleForm>
        </Edit>
    );
};

export default RecipeCatsEdit;