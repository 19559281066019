import React from 'react';
import {SimpleForm,TextInput,BooleanInput} from 'react-admin';

const QuestCategoryForm = (props) => {
    return (
        <SimpleForm {...props} redirect={"list"}>
            <BooleanInput source={"deleted"}/>
            <TextInput source={"title"} label={"نام"}/>
        </SimpleForm>
    );
};

export default QuestCategoryForm;