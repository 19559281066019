import React from 'react';
import {
    AutocompleteArrayInput, Button,
    ChipField,
    ReferenceArrayInput,
    required, useMutation
} from "react-admin";
import resource from "../../config/resource";
import useCustomNotify from "../../components/useCustomNotify";
import CustomForm from "../../components/form/CustomForm";
import {Card, CardContent} from "@material-ui/core";

const Aside = (props) => {
    const values = props.values || props.record;
    const notify = useCustomNotify();
    const [sendTest, {loading}] = useMutation()
    const onSendTest = (values) => {
        if ([values, values.data, values.data?.body, values.data?.channel].some(v => v == undefined)) {
            notify("Please fill all the required fields!", "error");
            return;
        }
        sendTest({
            type: 'sendTestNotification',
            payload: values
        }, {
            onSuccess: notify,
            onFailure: notify
        })
    }
    return (
        <Card style={{width: 400,marginRight:15}}>
            <CardContent>
                <CustomForm initialValues={{data: values?.data, image: values?.image}} save={onSendTest} saving={loading} toolbar={null}>
                    {(_,form) => (
                        <>
                            <ReferenceArrayInput reference={resource.user} source="testUsers"
                                                 filterToQuery={searchText => ({Username: searchText.trim().substring(1)})}
                                                 validate={required()}>
                                <AutocompleteArrayInput fullWidth optionText={values => `${values.name || ""}-${values.username}`}>
                                    <ChipField/>
                                </AutocompleteArrayInput>

                            </ReferenceArrayInput>
                            <Button variant={"contained"} disabled={loading} fullWidth label={"send test"} onClick={form.submit}/>
                        </>
                    )}
                </CustomForm>
            </CardContent>

        </Card>
    )
}

export default Aside;