const resource = {
    user:"user",
    remoteConfig:"remoteconfig",
    abTest:"abtest",
    feedback:"feedback",
    faq:"faq",
    faqCategory:"faqCategory",
    faqSubCategory:"faqSubcat",
    supportTicketLabel:"supportTicketLabel",
    supportTicket:"supportTicket",
    supportTicketReply:"reply",
    exercise:"exercise",
    diet:"diet",
    dietType:"dietType",
    predefinedDiet:"preDefinedDiet",
    dietTypeCategory:"dietTypeCategory",
    specialType:"specialType",
    recipeCategory:"recipeCats",
    foodTag:"foodTag",
    foodUnit:"foodUnit",
    hatedFood:"hatedFood",
    packageCalorie:"packageCalorie",
    mealCategory:"mealCategory",
    disease:"disease",
    recommendation:"recommendation",
    recommendationCategory:"cats",
    discount:"discount",
    bulkDiscount:"bulkdiscount",
    promotion:"promotion",
    version:"version",
    tracker:"tracker",
    package:"package",
    plan:"plan",
    role:"role",
    invoice:"invoice",
    note:"note",
    log:"log",
    subscription:"subscription",
    notification:"notification",
    automaticNotification:"automaticNotification",
    sms:"sms",
    food:"food",
    recipe:"recipe",
    groceryCategory:'groceryCategory',
    automaticSms:'automaticSms',
    costSource:'costSource',
    costLog:'costLog',
    questCategory:'questCategory',
    quest:'quest'
}
export default resource;