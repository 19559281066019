import React from 'react';
import {BooleanInput, CheckboxGroupInput, Edit, ReferenceArrayInput, SimpleForm, TextInput} from 'react-admin';
import resource from "../../config/resource";

const SpecialTypeEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <BooleanInput source="deleted" />
                <TextInput source="name" />
                <ReferenceArrayInput source="suitableTags" reference={resource.foodTag} label="Suitable Tags" >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
                <ReferenceArrayInput source="notSuitableTags" reference={resource.foodTag} label="Not Suitable Tags" >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export default SpecialTypeEdit;