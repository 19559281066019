import React from 'react';
import {ArrayField, BooleanField, Datagrid, DateField, Show, SimpleShowLayout, TextField} from 'react-admin';
import TextArrayField from "../../components/fields/TextArrrayField";

const VersionShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="version"/>
                <TextArrayField source={"market"} fullWidth/>
                <ArrayField source={"versionLog"}>
                    <Datagrid>
                        <TextField source={"type"}/>
                        <TextField source={"text"}/>
                    </Datagrid>
                </ArrayField>
                <BooleanField addLabel source="isForce"/>
                <DateField source="createdAt" locales="fa-IR"/>
                <DateField source="updatedAt" locales="fa-IR"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default VersionShow;