import React from 'react';

import {DatePicker as MuiDatePicker} from "@material-ui/pickers";
import {useInput, useTranslate} from "react-admin";
import {FormControl} from "@material-ui/core";

const DatePicker = ({source,...props}) => {
    const translate = useTranslate();
    const {
        input: {name, onChange, value, ...rest},
        meta: {touched, error},
        isRequired
    } = useInput({source,...props});
    return (
        <FormControl variant={props.variant || "filled"} margin={props.margin || "dense"}>
            <MuiDatePicker
                id={props.source}
                label={props.label || source}
                clearable
                autoOk={true}
                okLabel="تأیید"
                cancelLabel="لغو"
                clearLabel="پاک کردن"
                format={'jYYYY/jMM/jDD'}
                // emptyLabel={"-"}
                value={value || null}
                onChange={onChange}
                inputVariant={props.variant || "filled"}
                inputProps={rest}
                error={!!(touched && error)}
                helperText={(touched && translate(error)) ||'​' }
                required={isRequired}
                className={props.className}
            />
        </FormControl>


    );
};


export default DatePicker;