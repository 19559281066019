import React from 'react';
import {DateField, Edit, ReferenceField, SimpleForm, TextField, TextInput} from 'react-admin';
import resource from "../../config/resource";

const NoteEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <DateField locales="fa-IR" source="createdAt" label="زمان ساخت" />
                <DateField
                    locales="fa-IR"
                    source="updatedAt"
                    label="زمان آخرین بروزرسانی"
                />
                <ReferenceField reference={resource.user} source="author" label="نویسنده">
                    <TextField source="name" />
                </ReferenceField>
                <TextInput source="text" label="متن یادداشت" fullWidth multiline />
            </SimpleForm>
        </Edit>
    );
};

export default NoteEdit;