import React from 'react';
import {Button, useMutation, TextInput, required} from "react-admin";
import SendIcon from "@material-ui/icons/Send";
import useCustomNotify from "../../components/useCustomNotify";
import useModal from "../../components/useModal";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomForm from "../../components/form/CustomForm";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const SendSmsButton = ({record}) => {

    const {open,onClose,onOpen} = useModal();

    const notify = useCustomNotify();

    const [sendSms, {loading}] = useMutation();

    const onSendSms = (values) => {
        sendSms({
            type: 'sendTestSms',
            payload: values
        }, {
            onSuccess: notify,
            onFailure: notify
        })
    }

    return (
        <>
            <Button label={"sms"} disabled={loading} onClick={onOpen}
                    startIcon={<SendIcon/>}/>
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">ارسال اس‌ام‌اس</DialogTitle>

                <CustomForm initialValues={{testUsers: [record?._id]}} save={onSendSms} saving={loading} toolbar={null}>
                    {(_,form) => (
                        <>
                            <DialogContent>
                                <TextInput source={"data.text"} validate={required()}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={onClose} color="primary" label={"لغو"}/>
                                <Button onClick={form.submit} color="primary" label={"ارسال"}/>
                            </DialogActions>
                        </>
                    )}
                </CustomForm>
            </Dialog>
        </>

    );
};

export default SendSmsButton;