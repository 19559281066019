import React, {useState} from 'react';
import {
    Edit,
} from 'react-admin';
import Aside from "./Aside";
import NotificationForm from "./NotificationForm";

const NotificationEdit = (props) => {
    const [formValues, setFormValues] = useState({})

    return (
        <Edit{...props} aside={<Aside values={formValues}/>}
        >
          <NotificationForm setFormValues={setFormValues}/>
        </Edit>
    );
};

export default NotificationEdit;