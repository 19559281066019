const choices =  {
    automatedTimes:[
        {name:'11:00',id:1},
        {name:'19:30',id:2},
        {name:'15:34',id:3},
        {name:'11:30',id:4},
    ],
    apiMethods:[
        {name:'GET',id:"GET"},
        {name:'POST',id:"POST"},
        {name:'PUT',id:"PUT"},
        {name:'PATCH',id:"PATCH"},
        {name:'DELETE',id:"DELETE"},
    ]
}
export default choices;