import React from 'react';
import {Create} from 'react-admin';
import AutomaticNotificationForm from "./AutomaticNotificationForm";

const AutomaticNotificationCreate = (props) => {

    return (
        <Create {...props} >
           <AutomaticNotificationForm/>
        </Create>
    );
};

export default AutomaticNotificationCreate;