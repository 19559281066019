import React from 'react';
import {BooleanInput, Edit, SimpleForm, TextInput} from 'react-admin';

const SupportTicketLabelEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="text" />
                <BooleanInput source="deleted" />
            </SimpleForm>
        </Edit>
    );
};

export default SupportTicketLabelEdit;