import React from 'react';
import {BooleanField, Show, SimpleShowLayout, TextField} from 'react-admin';

const QuestCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source={"title"} label={"نام"}/>
                <BooleanField source={"deleted"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default QuestCategoryShow;