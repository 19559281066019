import React from 'react';
import {BooleanField, Datagrid, DateField, List, NumberField, ReferenceField, TextField} from 'react-admin';
import PassedDayField from "../../components/fields/PassedDayField";
import TranslateField from "../../components/fields/TranslateField";
import GoalField from "../../components/fields/GoalField";
import resource from "../../config/resource";

const DietList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <ReferenceField source="user" reference={resource.user} label="نام">
                    <TextField source="name" />
                </ReferenceField>
                <DateField locales="fa-IR" source="createdAt" label="زمان ساخت رژیم" />
                <DateField locales={"fa-IR"} source={"startDate"} label="تاریخ شروع رژیم" />
                <PassedDayField source={"createdAt"} label="زمان سپری شده" />
                <GoalField source="goal" label="هدف" />
                <TranslateField source="difficulty" label="درجه سختی" />
                <NumberField source="calorie" label="کالری"/>
                <BooleanField addLabel source="isCanceled" label="لغو شده" />
            </Datagrid>
        </List>
    );
};

export default DietList;