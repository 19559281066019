import React from 'react';
import {List,Datagrid,ReferenceField,TextField,BooleanField,DateField,Filter,SelectInput,BooleanInput} from 'react-admin';
import resource from "../../config/resource";
import {toCsv} from "../../utils/utils";
const FeedbackList = (props) => {
    return (
        <List
            filters={<FeedbackFilter />}
            {...props}
            exporter={exporter}
        >
            <Datagrid rowClick="edit">
                <ReferenceField emptyText={"-"} source="user._id" reference={resource.user} label="نام" >
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="text" label="متن" />
                <TextField source="type" label="نوع" />
                <BooleanField addLabel source="checked" label="چک شده" />
                <BooleanField addLabel source="deleted" label="پاک شده" />
                <DateField locales="fa-IR" source="createdAt" label="زمان ساخت" />
            </Datagrid>
        </List>
    );
};

const FeedbackFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="نوع" source="type" alwaysOn choices={[
            { id: 'problem', name: 'Problem' },
            { id: 'rating', name: 'Rating' },
        ]} />
        <BooleanInput label="چک شده" source="checked" />
    </Filter>
);

const exporter = (feedbacks) => {
    const data = feedbacks.map(feedback => {
        const {id, _id, ...exportData} = feedback;
        return {
            ...exportData,
            user:feedback.user?.name
        };
    })
    toCsv(data,'feedbacks')
}

export default FeedbackList;