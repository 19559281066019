import React from 'react';
import {
    ArrayField,
    BooleanField,
    Button, Datagrid,
    DateField, EditButton,
    ImageField,
    NumberField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useMutation
} from 'react-admin';
import useCustomNotify from "../../components/useCustomNotify";
import SendIcon from "@material-ui/icons/Send";
import AutomatedTimeField from "../../components/fields/AutomatedTimeField";

const AutomaticNotificationShow = (props) => {

    return (
        <Show {...props} actions={<AutomaticNotificationActions/>}>
            <TabbedShowLayout {...props}>
                <Tab label={"details"}>
                    <BooleanField addLabel source="deleted"/>
                    <BooleanField addLabel source="paused"/>
                    <BooleanField addLabel source="scheduleSend"/>
                    <AutomatedTimeField source={"time"}/>
                    <TextField addLabel source="status" emptyText={"-"}/>
                    <TextField addLabel source="segmentCount" emptyText={"-"}/>
                    <TextField addLabel source="sentCount" emptyText={"-"}/>
                    <TextField addLabel source="openCount" emptyText={"-"}/>
                    <TextField addLabel source="successSentCount" emptyText={"-"}/>
                    <DateField source="createdAt"/>
                    <DateField source="updatedAt"/>
                    <NumberField source="sentCount" emptyText={"-"}/>
                    <NumberField source="openCount" emptyText={"-"}/>
                    <TextField source="description" fullWidth emptyText={"-"}/>
                </Tab>
               <Tab label={"data"}>
                   <NumberField source={"data.controlGroupWeight"}/>
                   <ArrayField source={"data.variants"}>
                       <Datagrid >
                           <TextField source={"variant"} emptyText={"-"}/>
                           <NumberField source={"weight"} emptyText={"-"}/>
                           <TextField source={"title"} emptyText={"-"}/>
                           <TextField source={"body"} emptyText={"-"}/>
                           <ImageField source={"image"} emptyText={"-"}/>
                           <TextField source={"channel"} emptyText={"-"}/>
                           <TextField source={"actionUrl"} emptyText={"-"}/>
                       </Datagrid>
                   </ArrayField>
               </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const AutomaticNotificationActions = ({data:record,...props}) => {
    const notify = useCustomNotify();
    const [sendNotification, {loading}] = useMutation();
    const onSendNotification = (id) => {
        sendNotification({
            type: 'sendNotification',
            payload: {id}
        }, {
            onSuccess: notify,
            onFailure:notify
        })
    }
    return (
        <TopToolbar>
            {record?.status !== 'sent' &&
            <Button label={"ارسال"} disabled={loading} onClick={() => onSendNotification(record._id)}
                    startIcon={<SendIcon/>}/>}
            <EditButton basePath={props.basePath} record={record}/>
        </TopToolbar>
    )
}

export default AutomaticNotificationShow;