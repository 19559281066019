import React from 'react';
import {Create,SimpleForm,BooleanInput,TextInput,NumberInput,required} from 'react-admin';
const FaqCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <BooleanInput source="deleted" />
                <TextInput source="name" validate={[required()]}/>
                <NumberInput source="order" validate={[required()]}/>
            </SimpleForm>
        </Create>
    );
};

export default FaqCategoryCreate;