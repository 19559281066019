import React from 'react';
import {
    BooleanInput,
    Edit,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import resource from "../../config/resource";

const FaqSubcatEdit = (props) => {
    return (
        <Edit {...props} >
            <SimpleForm>
                <BooleanInput source="deleted" />
                <TextInput source="name" validate={[required()]}/>
                <ReferenceInput
                    source="category"
                    reference={resource.faqCategory}
                    label="Category"
                    validate={[required()]}
                >
                    <SelectInput
                        optionText="name"
                    />
                </ReferenceInput>
                <NumberInput source="order" validate={[required()]}/>
            </SimpleForm>
        </Edit>
    );
};

export default FaqSubcatEdit;