import React from "react";
import moment from 'moment-jalaali';
import {FunctionField} from 'react-admin';
import get from 'lodash/get';

const PassedDayField = ({source}) => {
    function getPassedDays(date){
        return moment(Date.now()).diff(moment(date),'days')+1
    }
    return (
    <FunctionField source={source} render={(record) => {
        const passedDays = getPassedDays(get(record, source))
        if(passedDays < 1)
            return `${passedDays} روز مانده تا شروع رژیم`
        if(passedDays <31)
            return `${passedDays} روز`
        else
            return "رژیم پایان یافته";
    }}/>
    );
};

PassedDayField.defaultProps={
    addLabel:true
}

export default PassedDayField;