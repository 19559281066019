import React from 'react';
import {Show,SimpleShowLayout,BooleanField,TextField,NumberField,DateField} from 'react-admin';
const FaqCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField addLabel source="deleted" />
                <TextField source="name" />
                <NumberField source="order" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default FaqCategoryShow;