import Service from "./Service";

class AutomaticSmsService extends Service{
    transformResponse(res) {
        const filterKey = res.filters && Object.keys(res.filters)[0];
        const {filters,...r} = res;
        return {
            ...r,
            filter:{
                type:filterKey,
                value:res.filters?.[filterKey]
            }
        };
    }
}

export default AutomaticSmsService;