import React from 'react';
import {Edit} from 'react-admin';
import AutomaticNotificationForm from "./AutomaticNotificationForm";

const AutomaticNotificationEdit = (props) => {
    return (
        <Edit {...props} >
            <AutomaticNotificationForm/>
        </Edit>
    );
};

export default AutomaticNotificationEdit;