import React from 'react';
import {BooleanInput, Edit, SimpleForm, TextInput} from 'react-admin';

const FoodTagEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm
                redirect="show"
            >
                <TextInput source="name"/>
                <BooleanInput source="deleted"/>
            </SimpleForm>
        </Edit>
    );
};

export default FoodTagEdit;