import React from 'react';
import {BooleanInput, Edit, required, SimpleForm, TextInput,SelectInput} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";

const PromotionEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" label="عنوان" validate={[required()]} />
                <DatePicker
                    source="endDate"
                    label="تاریخ پایان"
                />
                <SelectInput allowEmpty source={"market"} choices={[
                    {id: "play", name: "Google Play"},
                    {id: "bazaar", name: "Cafe Bazaar"},
                    {id: "myket", name: "Myket"},
                    {id: "pwa", name: "PWA"},
                    {id: "ios", name: "IOS"},
                    {id: "sam", name: "sam"},
                ]}/>
                <BooleanInput source="deleted" label="پاک شده" />
            </SimpleForm>
        </Edit>
    );
};

export default PromotionEdit;