import React from 'react';
import {BooleanField, Datagrid, DateField, List, TextField} from 'react-admin';
import {toCsv} from "../../utils/utils";

const SupportTicketLabelList = (props) => {
    return (
        <List {...props} optionvalue="_id" exporter={exporter}>
            <Datagrid rowClick="show">
                <TextField source="text" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

const exporter = (supportTicketLabels) => {
    const data = supportTicketLabels.map(supportTicketLabel => {
        const {id, _id, ...exportData} = supportTicketLabel;
        return exportData;
    })
    toCsv(data, 'supportTicketLabels');
}

export default SupportTicketLabelList;