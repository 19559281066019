import React from 'react';
import {Edit,SimpleForm,BooleanInput,TextInput,NumberInput,required} from 'react-admin';
const FaqCategoryEdit = (props) => {
    return (
        <Edit {...props} >
            <SimpleForm>
                <BooleanInput source="deleted" />
                <TextInput source="name" validate={[required()]}/>
                <NumberInput source="order" validate={[required()]} />
            </SimpleForm>
        </Edit>
    );
};

export default FaqCategoryEdit;