import React from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';

const RecommendationCategoryShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField
                    source="name"
                    label="Name"
                />
            </SimpleShowLayout>
        </Show>
    );
};

export default RecommendationCategoryShow;