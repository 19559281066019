import React from 'react';
import {BooleanInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";
import resource from "../../config/resource";

const DiscountEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <BooleanInput source="deleted" label="پاک شده" />
                <TextInput source="code" label="کد" />
                <NumberInput source="limit" label="محدودیت تعداد" />
                <DatePicker
                    source="expirationDate"
                    label="تاریخ انقضا"
                />
                <ReferenceInput reference={resource.plan} source={"plan"}>
                    <SelectInput optionText={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`} resettable/>
                </ReferenceInput>
                <TextInput source="description" label="توضیح" />
            </SimpleForm>
        </Edit>
    );
};

export default DiscountEdit;