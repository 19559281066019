import React from 'react';
import {
    ArrayInput,
    Create,
    FormDataConsumer,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    TabbedForm, FormTab,
    SimpleFormIterator,
    TextInput, BooleanInput,AutocompleteArrayInput
} from 'react-admin';
import resource from "../../config/resource";
import Upload from "../../components/inputs/Upload";

const DietTypeCreate = (props) => {
    return (
        <Create {...props}>
            <TabbedForm redirect="list">
                <FormTab label={"details"}>
                    <BooleanInput source="completed" label="completed" defaultValue={false}/>
                    <Upload segment={"dietType"} source={"image"} type={"image"}/>
                    <TextInput source="name" validate={[required()]} />
                    <ReferenceInput source="dietTypeCategory" reference={resource.dietTypeCategory} >
                        <SelectInput source="name" />
                    </ReferenceInput>
                    <SelectInput source="type" choices={[
                        { id: "hourly", name: "Hourly Fasting" },
                        { id: "daily", name: "Daily Fasting" },
                    ]} resettable />
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.type && (
                                <NumberInput source="fasting" />
                            )
                        }
                    </FormDataConsumer>
                    <NumberInput source="days" />
                    <TextInput
                        source="description"
                        fullWidth
                        multiline
                        validate={[required()]}
                    />
                    <ReferenceArrayInput
                        source="packageCalories"
                        reference={resource.packageCalorie}
                        label="Calorie Amount Steps"
                        validate={[required()]}
                    >
                        <SelectArrayInput optionText="amount" />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput
                        source="allowedFoods"
                        reference={resource.food}
                        label="Allowed Foods"
                    >
                        <AutocompleteArrayInput optionText="name"/>
                    </ReferenceArrayInput>
                    <ReferenceArrayInput
                        source="notAllowedFoods"
                        reference={resource.food}
                        label="Not Allowed Foods"
                    >
                        <AutocompleteArrayInput optionText="name"/>
                    </ReferenceArrayInput>
                    <TextInput multiline source={"sideEffects"}/>
                    <TextInput multiline source={"benefits"}/>
                    <TextInput multiline source={"recommendations"}/>
                    <TextInput multiline source={"target"}/>
                </FormTab>
              <FormTab label={"meal ratio array"}>
                  <ArrayInput source="mealRatioArray" label="Meal Ratio Arrays" validate={[required()]}>
                      <SimpleFormIterator>
                          <TextInput source="type" label={"type"}/>
                          <ArrayInput source="mealRatios" label="Meal Ratio Array" validate={[required()]}>
                              <SimpleFormIterator>
                                  <SelectInput
                                      source="meal"
                                      choices={[
                                          { id: "breakfast", name: "breakfast" },
                                          { id: "lunch", name: "lunch" },
                                          { id: "dinner", name: "dinner" },
                                          { id: "snack1", name: "snack1" },
                                          { id: "snack2", name: "snack2" },
                                          { id: "snack3", name: "snack3" },
                                          { id: "snack4", name: "snack4" },
                                      ]}
                                      label={"meal"}
                                      validate={[required()]}
                                  />
                                  <TextInput source="meal_text" label={"text"}/>
                                  <NumberInput source="ratio" label="ratio(%)" validate={[required()]} />
                              </SimpleFormIterator>
                          </ArrayInput>
                      </SimpleFormIterator>
                  </ArrayInput>
              </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default DietTypeCreate;