import React from 'react';
import {Create, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput,maxLength} from 'react-admin';
import resource from "../../config/resource";

const RecommendationCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    multiline fullWidth
                    source="text"
                    validate={maxLength(2000)}
                    label="Text"
                />
                <ReferenceArrayInput label="Categories" source="cat.id" reference={resource.recommendationCategory}>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default RecommendationCreate;