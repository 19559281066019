import resource from "./resource";
import api from "./api";

const authProvider = {
    login: async ({username, code}) => {
        return api[resource.user].login({username, code}).then((res) => {
            localStorage.setItem('token', res.token)
            localStorage.setItem('refreshToken', res.refreshToken)
            localStorage.setItem('loginDate', new Date().toJSON())
        })
    },
    checkError: error => {
        if(error.message==="token expired"){
            return api[resource.user].refreshToken().then(res => {
                if(res.success){
                    localStorage.removeItem("token");
                    localStorage.setItem('token', res.token)
                    localStorage.removeItem("refreshToken");
                    localStorage.setItem('refreshToken', res.refreshToken)
                    return Promise.resolve();
                }
                return Promise.reject();
            })
        }
        return Promise.resolve()
    },
    checkAuth: params => {
        return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("roles");
        localStorage.removeItem("user");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("loginDate");
        return Promise.resolve();
    },
    getIdentity: () => {
        return api[resource.user].getCurrentUser().then(user => {
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('roles', user.roles)
        })
    },
    getPermissions: params => {
        const userRoles = localStorage.getItem("roles")?.split(',');
        return userRoles ? Promise.resolve(userRoles) : Promise.reject();
    },
}
export default authProvider;