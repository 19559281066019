import React from 'react';
import {
    BooleanInput,
    FormDataConsumer,
    FormTab,
    required,
    SelectArrayInput,
    SelectInput,
    TabbedForm,
    TextInput
} from "react-admin";
import DateTimePicker from "../../components/inputs/DateTimePicker";
import DatePicker from "../../components/inputs/DatePicker";
import {FormSpy} from "react-final-form";

const SmsForm = ({setValues, ...props}) => {
    return (
        <TabbedForm redirect="list" {...props}>
            <FormTab label={"details"}>
                <BooleanInput source="scheduleSend"/>
                <FormDataConsumer>
                    {({formData}) =>
                        formData.scheduleSend && (
                            <DateTimePicker source="scheduleSendDateTime" validate={[required()]}/>
                        )
                    }
                </FormDataConsumer>
                <TextInput source="description"/>
            </FormTab>
            <FormTab label={"filters"}>
                <DatePicker source="filters.registerStartDate" label="Register Start Date"/>
                <DatePicker source="filters.registerEndDate" label="Register End Date"/>
                <SelectInput source="filters.sex" choices={[
                    {id: "male", name: "Male"},
                    {id: "female", name: "Female"}
                ]} label="Sex" resettable/>
                <SelectArrayInput source="filters.market" choices={[
                    {id: "play", name: "Google Play"},
                    {id: "bazar", name: "Cafe Bazaar"},
                    {id: "myket", name: "Myket"},
                ]} label="Market"/>
                <BooleanInput source="filters.hasPurchased" label="Has Purchased"/>
                <BooleanInput source="filters.hasWeightLog" label="Has Weight Log"/>
                <BooleanInput source="filters.hasWaterLog" label="Has Water Log"/>
                <BooleanInput source="filters.hasFoodLog" label="Has Food Log"/>
                <BooleanInput source="filters.hasSubscription" label="Has Subscription"/>
            </FormTab>
            <FormTab label={"data"}>
                <TextInput source="data.text" label="Text" validate={[required()]}/>
            </FormTab>
            <FormSpy onChange={props => setValues(props.values)}/>
        </TabbedForm>
    );
};

export default SmsForm;