import React from 'react';
import {List,Datagrid,TextField,ReferenceField,NumberField,DateField,BooleanField,Filter,ReferenceInput,SelectInput} from 'react-admin';
import resource from "../../config/resource";
import {toCsv} from "../../utils/utils";
const FaqList = (props) => {
    return (
        <List filters={<FAQFilter />} {...props} exporter={exporter}>
            <Datagrid rowClick="show">
                <TextField source="question" />
                <TextField source="answer" />
                <ReferenceField source="subcategory" reference={resource.faqSubCategory} >
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="order" />
                <NumberField source="impressions" />
                <NumberField source="positive" />
                <NumberField source="negative" />
                <NumberField source="reports" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

const FAQFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput
            source="subcategory"
            reference={resource.faqSubCategory}
            label="Subcategory"
            alwaysOn
        >
            <SelectInput
                optionText="name"
            />
        </ReferenceInput>
    </Filter>
);

const exporter = async (faqs,fetchRelatedRecords) => {
    const relatedSubCategories = await fetchRelatedRecords(faqs,'subcategory',resource.faqSubCategory);
    const data = faqs.map(faq => {
        const {id,_id,...exportData} = faq;
        return {
            ...exportData,
            subcategory: relatedSubCategories[faq.subcategory]?.name || '-'
        }
    })
    toCsv(data, 'faqs');
}
export default FaqList;