import React from 'react';
import {Button, useMutation, useRefresh} from "react-admin";
import useCustomNotify from "../../components/useCustomNotify";

const DeleteSubscriptionButton = ({record}) => {
    const refresh = useRefresh();
    const notify = useCustomNotify();
    const [deleteSub,{loading}] = useMutation({
        type:'delete',
        resource:'subscription',
        payload:{data:{userId:record.id}}
    },{
        onSuccess:(res) => {
            refresh();
            notify(res);
        },
        onFailure:notify
    })
    return record.subscription ? <Button label={'حذف اشتراک'} onClick={deleteSub} disabled={loading} variant={"outlined"} /> : null;
};

export default DeleteSubscriptionButton;