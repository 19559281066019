import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    TextField
} from 'react-admin';
import resource from "../../config/resource";

const PromotionList = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show">
                <TextField source="title" label="عنوان" />
                <DateField emptyText={"-"} source="startDate" locales="fa-IR" label="تاریخ شروع" />
                <DateField emptyText={"-"} source="endDate" locales="fa-IR" label="تاریخ پایان" />
                <ReferenceField emptyText={"-"} reference={resource.plan} source={"plan"} label={"نوع اشتراک"}>
                    <FunctionField
                        label="plan"
                        render={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`}
                    />
                </ReferenceField>
                <TextField source={"market"} emptyText={"-"}/>
                <BooleanField addLabel source="deleted" label="پاک شده" />
                <NumberField source="percent" label="درصد" />
                <DateField source="createdAt" locales="fa-IR" label="تاریخ ساخت" />
                <DateField source="updatedAt" locales="fa-IR" label="آخرین بروزرسانی" />
            </Datagrid>
        </List>
    );
};

export default PromotionList;