import React from 'react';
import resource from "../../config/resource";
import {
    ChipField,
    Datagrid,
    DateField,
    ReferenceArrayField,
    ReferenceField, ReferenceManyField,
    SingleFieldList,
    TextField
} from "react-admin";

const UserChatHistory = ({userIdSource,...props}) => {
    return (
        <ReferenceManyField reference={resource.supportTicket} target={"user"} label={""} record={{id:props.record[userIdSource]}} >
            <Datagrid rowClick={"show"}>
                <ReferenceField label="کاربر" source="user" reference={resource.user} link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField emptyText={"-"} source="messages[0].text" label="متن گزارش" />
                <TextField emptyText={"-"} source="messages[1].text" label="پاسخ" />
                <ReferenceField emptyText={"ندارد"} source="admin" reference={resource.user} link="show" label={"ادمین"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField emptyText={"ندارد"} source="faq" reference={resource.faq} label={"کتگوری"}>
                    <TextField source="question" />
                </ReferenceField>
                <ReferenceArrayField
                    source="labels"
                    reference={resource.supportTicketLabel}
                    label="برچسب ها"
                >
                    <SingleFieldList >
                        <ChipField source="text" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="ticketStatus" label="وضعیت تیکت" />
                <TextField source="userReaction" label="بازخورد کاربر" />
                <DateField locales={"fa-IR"} source="createdAt" label="زمان ساخت تیکت" />
                <DateField locales={"fa-IR"} source="updatedAt" label="زمان آخرین بروزرسانی" />
                <DateField emptyText={"-"} locales={"fa-IR"} source="messages[1].dateTime" label="زمان پاسخگویی" />
            </Datagrid>
        </ReferenceManyField>
    );
};

export default UserChatHistory;