import React from 'react';
import {
    Create,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";
import resource from "../../config/resource";

const BulkdiscountCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <NumberInput source="percent" label="درصد" />
                <NumberInput source="count" label="محدودیت تعداد" validate={[required()]}/>
                <DatePicker
                    source="expirationDate"
                    label="تاریخ انقضا"
                    locales="fa-IR"
                />
                <ReferenceInput reference={resource.plan} source={"plan"}>
                    <SelectInput optionText={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`} resettable/>
                </ReferenceInput>
                <TextInput source="description" label="توضیح" />
                <SelectInput
                    source="type"
                    label="نوع"
                    choices={[
                        { id: "bulk", name: "Bulk" },
                        { id: "gift", name: "Gift" },
                    ]}
                    defaultValue="bulk"
                />
            </SimpleForm>
        </Create>
    );
};

export default BulkdiscountCreate;