import React from 'react';
import {BooleanField, Datagrid, DateField, List, TextField} from 'react-admin';
import WebsiteMediumLink from "../../components/fields/WebsiteMediumLink";

const TrackerList = (props) => {
    return (
        <List {...props} >
            <Datagrid rowClick="show">
                <TextField source="source" />
                <TextField source="medium" />
                <WebsiteMediumLink source="medium" label="Website Link"/>
                <TextField source="trackerLink" />
                <TextField source="description" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

export default TrackerList;