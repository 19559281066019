import React from 'react';
import {Datagrid, DateField, Filter, List, TextField, TextInput} from 'react-admin';

const RecommendationCategoryList = (props) => {
    return (
        <List
            filters={<CatFilter />}
            {...props}
        >
            <Datagrid rowClick="show">
                <TextField source="name" />
                <DateField source="createdAt" label="Create Date" locales={"fa-IR"}/>
                <DateField source="updatedAt" label="Last Update" locales={"fa-IR"}/>
            </Datagrid>
        </List>
    );
};
const CatFilter = (props) => (
    <Filter {...props}>
        <TextInput className="Search" label="Search" source="text" alwaysOn />
    </Filter>
);
export default RecommendationCategoryList;