import React from 'react';
import {
    BooleanField,
    DateField,
    FunctionField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import resource from "../../config/resource";

const PromotionShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="title" label="عنوان" />
                <DateField
                    emptyText={"-"}
                    source="startDate"
                    label="تاریخ شروع"
                    locales="fa-IR"
                />
                <DateField
                    emptyText={"-"}
                    source="endDate"
                    label="تاریخ پایان"
                    locales="fa-IR"
                />
                <NumberField source="percent" label="درصد" />
                <ReferenceField source="plan" reference={resource.plan}>
                    <FunctionField
                        label="plan"
                        render={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`}
                    />
                </ReferenceField>
                <TextField source={"market"} emptyText={"-"}/>
                <BooleanField addLabel source="deleted" label="پاک شده" />
            </SimpleShowLayout>
        </Show>
    );
};

export default PromotionShow;