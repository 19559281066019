import React from 'react';
import {BooleanInput, Create, SimpleForm, TextInput} from 'react-admin';

const DietTypeCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm
                redirect="show"
            >
                <TextInput source="name" />
                <BooleanInput source="completed" />
            </SimpleForm>
        </Create>
    );
};

export default DietTypeCategoryCreate;