import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    FormTab,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from "react-admin";
import resource from "../../config/resource";
import useSearchResource from "../../components/resource/useSearchResource";
import set from 'lodash/set';
import {getQueryParam} from "../../utils/utils";
import Upload from "../../components/inputs/Upload";

// todo recipe-admin only has access to icon
const FoodForm = (props) => {
    const getUnits = useSearchResource(resource.foodUnit);
    const redirect = getQueryParam("redirect") || 'list';
    return (
        <TabbedForm {...props} redirect={redirect} validate={(values) => validateFood(values,getUnits)}>
            <FormTab label={"details"}>
                <BooleanInput source="deleted" label="deleted"/>
                <TextInput source="name" validate={[required()]} />
                <ReferenceInput
                    source="primaryFoodUnit._id"
                    reference={resource.foodUnit}
                    label="Primary Food Unit"
                    validate={[required()]}
                >
                    <SelectInput optionText={"name"}/>
                </ReferenceInput>
                <Upload segment={"food"} type={"image"} source={"icon"}/>
            </FormTab>
            <FormTab label={"unit ratio array"}>
                <ArrayInput source="foodUnitRatioArray" label="">
                    <SimpleFormIterator>
                        <ReferenceInput
                            source="unitId"
                            reference={resource.foodUnit}
                            label="food unit"
                            validate={[required()]}
                        >
                            <SelectInput optionText={"name"} resettable/>
                        </ReferenceInput>
                        <NumberInput
                            source="ratio"
                            label="ratio(gr)"
                            validate={[required()]}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label={"cooked food array"}>
                <ArrayInput source="cookedFoods" label="">
                    <SimpleFormIterator>
                        <ReferenceInput
                            source="food"
                            reference={resource.food}
                            label="Cooked Food Name"
                            validate={[required()]}
                        >
                            <AutocompleteInput
                                optionText="name"
                            />
                        </ReferenceInput>
                        <NumberInput
                            source="ratio"
                            label="ratio(gr)"
                            validate={[required()]}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label={"tags"}>
                <ReferenceArrayInput
                    source="tags"
                    reference={resource.foodTag}
                    label="Tags"
                    fullWidth
                >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>

                <ReferenceArrayInput
                    source="suitableDiseases"
                    reference={resource.disease}
                    label="suitable fort diseases"
                    fullWidth
                >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
                <ReferenceArrayInput
                    source="notSuitableDiseases"
                    reference={resource.disease}
                    label="not suitable for diseases"
                    fullWidth
                >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
                <ReferenceArrayInput
                    source="hatedFoods"
                    reference={resource.hatedFood}
                    label="Hated Foods"
                    fullWidth
                >
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
                <CheckboxGroupInput
                    source="seasons"
                    label="suitable for seasons"
                    defaultValue={["summer", "fall", "winter", "spring"]}
                    choices={[
                        { id: "summer", name: "تابستان" },
                        { id: "fall", name: "پاییز" },
                        { id: "winter", name: "زمستان" },
                        { id: "spring", name: "بهار" },
                    ]}
                />
                <BooleanInput
                    source="notSuitablePregnancy"
                    label="not suitable for pregnancy"
                    defaultValue={false}
                />
                <BooleanInput
                    source="notSuitableBreastfeeding"
                    label="not suitable for breastfeeding"
                    defaultValue={false}
                />
            </FormTab>
            <FormTab label={"Food Fact( /100gr )"}>
                <NumberInput
                    source="foodFact.calorieAmount"
                    label="Calorie Amount"
                    validate={[required()]}
                />
                <NumberInput source="foodFact.proteinAmount" label="Protein Amount" />
                <NumberInput source="foodFact.fatAmount" label="Fat Amount" />
                <NumberInput
                    source="foodFact.carbohydrateAmount"
                    label="Carbohydrate Amount"
                />
                <NumberInput source="foodFact.sugarAmount" label="Sugar Amount" />
                <NumberInput source="foodFact.fiberAmount" label="Fiber Amount" />
                <NumberInput source="foodFact.sodiumAmount" label="Sodium Amount" />
                <NumberInput
                    source="foodFact.potassiumAmount"
                    label="Potassium Amount"
                />
                <NumberInput source="foodFact.calciumAmount" label="Calcium Amount" />
                <NumberInput
                    source="foodFact.magnesiumAmount"
                    label="Magnesium Amount"
                />
                <NumberInput source="foodFact.ironAmount" label="Iron Amount" />
                <NumberInput
                    source="foodFact.cholesterolAmount"
                    label="Cholesterol Amount"
                />
                <NumberInput source="foodFact.phosphorAmount" label="Phosphor Amount" />
                <NumberInput
                    source="foodFact.saturatedFatAmount"
                    label="Saturated FatAmount"
                />
                <NumberInput
                    source="foodFact.polyunsaturatedFatAmount"
                    label="Polyunsaturated Fat Amount"
                />
                <NumberInput
                    source="foodFact.monounsaturatedFatAmount"
                    label="Monounsaturated Fat Amount"
                />
                <NumberInput
                    source="foodFact.transFatAmount"
                    label="Trans Fat Amount"
                />
            </FormTab>
        </TabbedForm>
    );
};

const validateFood = (values,getUnits) => {
    const gramUnitId = getUnits({name:'گرم'})[0]?.id
    var unitError = false;
    const errors = {};

    if (values.primaryFoodUnit !== gramUnitId) {
        if (values.primaryFoodUnit) {
            if (values.primaryFoodUnit._id !== gramUnitId) {
                unitError = true;
            }
        } else {
            unitError = true;
        }

        for (var j in values.foodUnitRatioArray) {
            if (values.foodUnitRatioArray[j]?.unitId) {
                if (values.primaryFoodUnit === values.foodUnitRatioArray[j].unitId) {
                    unitError = false;
                }
                if (values.primaryFoodUnit) {
                    if (
                        values.primaryFoodUnit._id === values.foodUnitRatioArray[j].unitId
                    ) {
                        unitError = false;
                    }
                }
            }
        }
    }
    if (unitError) {
        set(errors,'primaryFoodUnit._id',"Unit ratio array must include primary food unit ratio!")
    }
    return errors;
};
export default FoodForm;