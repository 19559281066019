import React from 'react';
import {
    Show,
    TabbedShowLayout,
    TextField,
    ArrayField,
    Datagrid,
    NumberField,
    DateField,
    ReferenceField,
    BooleanField,Tab
} from 'react-admin';
import resource from "../../config/resource";
import {Grid} from "@material-ui/core";
import CustomTab from "../../components/Layout/CustomTab";

const AbTestShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <CustomTab label={"details"}>
                <Grid container>
                    <TextField source="description"/>
                    <DateField source="startDate" locales={"fa-IR"}/>
                    <DateField source="endDate" locales={"fa-IR"}/>
                    <BooleanField addLabel source="enable"/>
                    <BooleanField addLabel source="deleted"/>
                </Grid>
            </CustomTab>
            <Tab label={"variants"}>
                <ArrayField source="variants" label={""}>
                    <Datagrid>
                        <TextField source="name"/>
                        <NumberField source="weight"/>
                        <ArrayField label="Remote Configs" source="remoteConfigs">
                            <Datagrid>
                                <ReferenceField link="show" source="key" reference={resource.remoteConfig}>
                                    <TextField source="key"/>
                                </ReferenceField>
                                <TextField source="value"/>
                            </Datagrid>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);


export default AbTestShow;