import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    SelectArrayInput,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput
} from 'react-admin';
import resource from "../../config/resource";

const RecommendationList = (props) => {
    return (
        <List
            filters={<RecFilter />}
            {...props}
        >
            <Datagrid rowClick="edit" expand={<ShowCategories />}>
                <TextField source="text" />
                <ReferenceArrayField
                    source="category"
                    reference={resource.recommendationCategory}
                    target="id"
                    label="Categories"
                >
                    <SingleFieldList>
                        <TextField source="name" className="packageFoods" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField addLabel source="deleted" />
                <DateField source="createdAt" label="Create Date" locales={"fa-IR"}/>
                <DateField source="updatedAt" label="Last Update" locales={"fa-IR"}/>
            </Datagrid>
        </List>
    );
};

const ShowCategories = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceArrayField reference={resource.recommendationCategory} target="id" label="Categories">
                <SingleFieldList>
                    <TextField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);
const RecFilter = (props) => (
    <Filter {...props}>
        <TextInput className="Search" label="Search" source="text" alwaysOn />
        <ReferenceArrayInput
            label="Categories"
            source="cat.id"
            reference={resource.recommendationCategory}
            allowEmpty
        >
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
    </Filter>
);

export default RecommendationList;