import React from 'react';
import {BooleanField, Datagrid, DateField, Filter, List, SelectInput, TextField} from 'react-admin';

const SmsList = (props) => {
    return (
        <List {...props} filters={<SMSFilter />}>
            <Datagrid rowClick="show">
                <TextField source="description" />
                <TextField source="status" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
                <TextField source="segmentCount" />
                <TextField source="sentCount" />
                <TextField source="openCount" />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

const SMSFilter = (props) => (
    <Filter {...props}>
        <SelectInput
            source="status"
            alwaysOn
            choices={[
                { id: "processing", name: "Processing" },
                { id: "readyToSend", name: "Ready To Send" },
                { id: "sending", name: "Sending" },
                { id: "sent", name: "Sent" },
            ]}
        />
    </Filter>
);

export default SmsList;