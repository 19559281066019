import Service from "./Service";

class CostLogService extends Service{
    transformRequest(req) {
        return {
            ...req,
            date:new Date(req.date).getTime()
        }
    }
}
export default CostLogService;