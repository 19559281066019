import React from 'react';
import {Create} from 'react-admin';
import QuestCategoryForm from "./QuestCategoryForm";

const QuestCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <QuestCategoryForm/>
        </Create>
    );
};

export default QuestCategoryCreate;