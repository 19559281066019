import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    FormDataConsumer,
    NumberInput,
    ReferenceInput, required, SelectInput,
    SimpleFormIterator, TextInput, FormTab, TabbedForm
} from "react-admin";
import resource from "../../config/resource";
import {FormSpy} from "react-final-form";
import RecipeTable from "./RecipeTable";
import {useSelector} from "react-redux";
import Upload from "../../components/inputs/Upload";
import set from "lodash/set";
import usePermission from "../../components/permission/usePermission";

const RecipeForm = (props) => {
    const hasPermission = usePermission();
    let resources = useSelector(state => state.admin.resources);
    return (
        <TabbedForm {...props} redirect="" validate={validateRecipe}>
            <FormTab label={"recipe"}>
                <BooleanInput source="deleted"/>
                <BooleanInput source="free"/>
                {hasPermission(`${resource.recipe}.edit.status.base`) && <SelectInput source="status" choices={[
                    {id: "nutritionVerification", name: "Nutrition Verification"},
                    {id: "rejected", name: "Rejected"},
                    {id: "contentVerification", name: "Content Verification"},
                    {
                        id: "verified",
                        name: "Verified",
                        disabled: !hasPermission(`${resource.recipe}.edit.status.verify`)
                    },
                ]} label="Channel"/>}
                <Upload segment={"recipeImage"} source={"image"}/>
                <Upload segment={"recipeVideo"} source={"video"} type={"video"}/>
                <Upload segment={"recipeVideo"} source={"videoLowRes"} type={"video"}/>
                <ReferenceInput
                    source="food"
                    reference={resource.food}
                    label="Food Name"
                >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => {
                        return val.trim().length > 2
                    }}/>
                </ReferenceInput>

                <TextInput fullWidth source="newFood"/>

                <NumberInput
                    fullWidth
                    source="time"
                    label="Total Time (min)"
                />
                <NumberInput source="weight" label="Total Weight (gr)"/>
                <NumberInput fullWidth source="serving"/>
                <SelectInput
                    fullWidth
                    source="difficulty"
                    choices={[
                        {id: "easy", name: "Easy"},
                        {id: "normal", name: "Normal"},
                        {id: "hard", name: "Hard"},
                    ]}
                />
                <TextInput
                    source="description"
                    fullWidth
                />
            </FormTab>
            <FormTab label={"ingredients"}>
                <ArrayInput source="ingredients">
                    <SimpleFormIterator>
                        <ReferenceInput
                            source={"food"}
                            reference={resource.food}
                            label="Food Name"
                            validate={[required()]}
                        >
                            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => {
                                return val.trim().length > 2
                            }}/>
                        </ReferenceInput>
                        <FormDataConsumer>
                            {({formData, scopedFormData, getSource}) => {
                                return (
                                    scopedFormData?.food && <ReferenceInput
                                        source={getSource("unit")}
                                        reference={resource.foodUnit}
                                        label="Food Unit"
                                        validate={[required()]}
                                        filter={{food: scopedFormData.food}}
                                    >
                                        <SelectInput optionText="name"/>
                                    </ReferenceInput>
                                )
                            }}
                        </FormDataConsumer>
                        <NumberInput
                            source={"amount"}
                            label="Amount"
                            validate={[required()]}
                        />
                        <ReferenceInput
                            source={"substitute"}
                            reference={resource.food}
                            label="Substitute Food Name"
                        >
                            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => {
                                return val.trim().length > 2
                            }}/>
                        </ReferenceInput>
                        <FormDataConsumer>
                            {({formData, scopedFormData, getSource}) => {
                                return (
                                    scopedFormData?.food &&
                                    <ReferenceInput label={"Cooked Food"} reference={resource.food}
                                                    filter={{id: resources[resource.food].data[scopedFormData?.food]?.cookedFoods?.map(cookedFood => cookedFood.food)}}
                                                    source={getSource("cooked")}>
                                        <SelectInput optionText={"name"} resettable/>
                                    </ReferenceInput>
                                )
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label={"instructions"}>
                <ArrayInput source="instructions">
                    <SimpleFormIterator>
                        <TextInput
                            source="text"
                            fullWidth
                            validate={[required()]}
                        />
                        <NumberInput source="start" label="Start(sec)"/>
                        <NumberInput source="end" label="Start(end)"/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormSpy subscription={{values: true}} render={(props) => <RecipeTable formValues={props.values}/>}/>
        </TabbedForm>
    );
};

const validateRecipe = (values) => {
    const errors = {};
    if (values.food || values.newFood)
        return errors;
    set(errors, 'food', "Food or newFood should be set")
    return errors;
};
export default RecipeForm;