import React, {useState} from 'react';

import {Create} from 'react-admin';
import Aside from "./Aside";
import SmsForm from "./SmsForm";

const SmsCreate = (props) => {
    const [values, setValues] = useState();
    return (
        <Create {...props} aside={<Aside values={values} />}>
            <SmsForm setValues={setValues}/>
        </Create>
    );
};

export default SmsCreate;