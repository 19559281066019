import React from 'react';
import {
    Create,
    TabbedForm,
    BooleanInput,
    TextInput,
    ArrayInput,
    // SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    SelectInput, FormTab,
} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";
import resource from "../../config/resource";
import {Grid} from "@material-ui/core";
import CustomFormIterator from "../../components/form/CustomFormIterator";

const AbTestCreate = (props) => {
    return (
        <Create {...props}>
            <TabbedForm redirect="show">
                <FormTab label={"details"}>
                    <BooleanInput source="enable"/>
                    <TextInput source="description"/>
                    <DatePicker source="startDate"/>
                    <DatePicker source="endDate"/>
                </FormTab>
                <FormTab label={"variants"}>
                    <ArrayInput source="variants">
                        <CustomFormIterator>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <TextInput source="name" label={"name"}/>
                                </Grid>
                                <Grid item>
                                    <NumberInput source="weight" label={"weight"}/>
                                </Grid>
                            </Grid>
                            <ArrayInput label="Remote Configs" source="remoteConfigs">
                                <CustomFormIterator>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <ReferenceInput label="Remote Config" source="key"
                                                            reference={resource.remoteConfig}>
                                                <SelectInput optionText="key"/>
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item>
                                            <TextInput source="value" label={"value"}/>
                                        </Grid>
                                    </Grid>
                                </CustomFormIterator>
                            </ArrayInput>
                        </CustomFormIterator>
                    </ArrayInput>
                </FormTab>

            </TabbedForm>
        </Create>
    );
};

export default AbTestCreate;