import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

const DiseaseCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" />
            </SimpleForm>
        </Create>
    );
};

export default DiseaseCreate;