import React from 'react';
import {
    BooleanField,
    DateField,
    FunctionField,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import resource from "../../config/resource";

const BulkdiscountShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <DateField emptyText={"-"} source="expirationDate" locales="fa-IR" label="تاریخ انقضا" />
                <NumberField source="percent" label="درصد" />
                <NumberField source="count" label="محدودیت تعداد" />
                <TextField source="description" label="توضیح" />
                <TextField source="consumed" label="استفاده شده" />
                <TextField source="type" label="نوع" />
                <ReferenceField label="نوع اشتراک" source="plan" reference={resource.plan} linkType={false}>
                    <FunctionField
                        label="plan"
                        render={record => `${record?.title} ${record.abTest && `(${record.abTest})`}`}
                    />
                </ReferenceField>
                <BooleanField addLabel source="deleted" label="پاک شده" />
                <DateField source="createdAt" locales="fa-IR" label="تاریخ ساخت" />
                <DateField source="updatedAt" locales="fa-IR" label="آخرین بروزرسانی" />
            </SimpleShowLayout>
        </Show>
    );
};

export default BulkdiscountShow;