import React from 'react';
import {Edit} from 'react-admin';
import QuestForm from "./QuestForm";

const QuestEdit = (props) => {
    return (
        <Edit {...props}>
            <QuestForm/>
        </Edit>
    );
};

export default QuestEdit;