import React from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    ChipField,
    Datagrid,
    DateField,
    Filter,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    SingleFieldList,
    TextField, useQueryWithStore
} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";
import resource from "../../config/resource";
import {toCsv} from "../../utils/utils";

const SupportTicketList = (props) => {
    return (
        <List exporter={exporter} filters={<ReportFilter />} sort={{order:'DESC',field:'id'}} filterDefaultValues={{ ticketStatus: "open" }} {...props}>
            <Datagrid rowClick="edit">
                <ReferenceField label="کاربر" source="user" reference={resource.user} link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField emptyText={"-"} source="messages[0].text" label="متن گزارش" />
                <ReferenceField emptyText={"ندارد"} source="admin" reference={resource.user} link="show" label={"ادمین"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField emptyText={"ندارد"} source="faq" reference={resource.faq} label={"کتگوری"}>
                    <TextField source="question" />
                </ReferenceField>
                <ReferenceArrayField
                    source="labels"
                    reference={resource.supportTicketLabel}
                    label="برچسب ها"
                >
                    <SingleFieldList >
                        <ChipField source="text" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="ticketStatus" label="وضعیت تیکت" />
                <TextField source="userReaction" label="بازخورد کاربر" />
                <DateField locales={"fa-IR"} source="createdAt" label="زمان ساخت تیکت" />
                <DateField locales={"fa-IR"} source="updatedAt" label="زمان آخرین بروزرسانی" />
                <DateField emptyText={"-"} locales={"fa-IR"} source="messages[1].dateTime" label="زمان پاسخگویی" />
            </Datagrid>
        </List>
    );
};

const ReportFilter = (props) => {
    const { data:roles } = useQueryWithStore({
        type: 'getList',
        resource: resource.role,
        payload: {}
    });
    return (
        <Filter {...props}>
            <SelectInput
                source="ticketStatus"
                choices={[
                    { id: "open", name: "Open" },
                    { id: "closed", name: "Closed" },
                    { id: "edited", name: "Edited" }
                ]}
                alwaysOn
            />
            <ReferenceInput
                source="faq"
                reference={resource.faq}
                label="FAQ"
            >
                <AutocompleteInput
                    optionText="question"
                />
            </ReferenceInput>
            <ReferenceInput
                source="admin"
                reference={resource.user}
                label="Admin"
                filter={{roles:roles?.filter(role => role.name==='admin')[0]._id}}
            >
                <SelectInput optionText={record => `${record.username}-${record.name || ""}`}/>
            </ReferenceInput>
            <ReferenceArrayInput
                source="labels"
                reference={resource.supportTicketLabel}
                label="Label"
            >
                <SelectArrayInput
                    optionText="text"
                />
            </ReferenceArrayInput>
            <SelectInput
                source="userReaction"
                choices={[
                    { id: "noReaction", name: "No Reaction" },
                    { id: "like", name: "Like" },
                    { id: "dislike", name: "Dislike" }
                ]}
            />
            <BooleanInput source="deleted" />
            <DatePicker
                source="startDate"
                label="Start date"
            />
            <DatePicker
                source="endDate"
                label="End date"
            />
            <DatePicker
                source="startReplyDate"
                label=" Reply date"
            />
        </Filter>
    )
};

const exporter = async (supportTickets,fetchRelatedRecords) => {
    const relatedUsers = await fetchRelatedRecords(supportTickets, 'user', resource.user);
    const relatedAdmins = await fetchRelatedRecords(supportTickets, 'admin', resource.user);
    const relatedLabels = await fetchRelatedRecords(supportTickets, 'labels', resource.supportTicketLabel);
    const relatedFaq = await fetchRelatedRecords(supportTickets, 'faq', resource.faq);
    const data = supportTickets.map(supportTicket => {
        const {messages,deleted,id,_id,...exportData} = supportTicket;
        return {
            ...exportData,
            user:relatedUsers[supportTicket.user].name,
            labels:supportTicket.labels.map(labelId => relatedLabels[labelId].text),
            message:supportTicket.messages[0].text,
            admin:relatedAdmins[supportTicket.admin]?.name || '-',
            faq:relatedFaq[supportTicket.faq]?.question || '-'
        }
    })
    toCsv(data,'supportTickets',['user'])
}
export default SupportTicketList;