import React from 'react';
import {
    BooleanInput,
    FormDataConsumer,
    FormTab,
    NumberInput,
    required,
    SelectInput,
    TabbedForm,
    TextInput
} from "react-admin";
import DateTimePicker from "../../components/inputs/DateTimePicker";
import Upload from "../../components/inputs/Upload";
import DatePicker from "../../components/inputs/DatePicker";
import {FormSpy} from "react-final-form";

const NotificationForm = ({setFormValues,...props}) => {
    return (
        <TabbedForm redirect="list" {...props}>
            <FormTab label={"details"}>
                <BooleanInput source="scheduleSend"/>
                <FormDataConsumer>
                    {({formData}) =>
                        formData.scheduleSend && (
                            <DateTimePicker source="scheduleSendDateTime" validate={[required()]}/>
                        )
                    }
                </FormDataConsumer>
                <TextInput source="description"/>
                <NumberInput source="abTestWeight" />
            </FormTab>
            <FormTab label={"filters"}>
                <DatePicker source="filters.registerStartDate" label="Register Start Date"/>
                <DatePicker source="filters.registerEndDate" label="Register End Date"/>
                <SelectInput source="filters.sex" choices={[
                    {id: "male", name: "Male"},
                    {id: "female", name: "Female"}
                ]} label="Sex" resettable/>
                <SelectInput source="filters.market" choices={[
                    {id: "play", name: "Google Play"},
                    {id: "bazaar", name: "Cafe Bazaar"},
                    {id: "myket", name: "Myket"},
                ]} label="Market" resettable/>
                <BooleanInput source="filters.hasPurchased" label="Has Purchased"/>
                <BooleanInput source="filters.hasWeightLog" label="Has Weight Log"/>
                <BooleanInput source="filters.hasWaterLog" label="Has Water Log"/>
                <BooleanInput source="filters.hasFoodLog" label="Has Food Log"/>
                <BooleanInput source="filters.hasSubscription" label="Has Subscription"/>
            </FormTab>
            <FormTab label={"data"}>
                <TextInput source="data.title" label="Title"/>
                <TextInput source="data.body" label="Body" fullWidth multiline validate={[required()]}/>
                <TextInput source="data.actionUrl" label="Action URL"/>
                <SelectInput source="data.channel" validate={[required()]} choices={[
                    {id: "bento", name: "Bento"},
                    {id: "recipe", name: "Recipe"},
                ]} label="Channel" resettable/>
                <Upload type={"image"} source={"data.image"} segment={"notification"}/>
            </FormTab>
            <FormSpy onChange={props => setFormValues(props.values)}/>
        </TabbedForm>
    );
};

export default NotificationForm;