import React from 'react';
import {
    Edit,
    SimpleForm,
    BooleanInput,
    ReferenceInput,
    TextInput,
    SelectInput,
    NumberInput,
    required,
    TopToolbar, ShowButton, Button, useMutation
} from 'react-admin';
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import resource from "../../config/resource";
import useCustomNotify from "../../components/useCustomNotify";
const FaqEdit = (props) => {
    return (
        <Edit actions={<FaqEditTopToolbar />} {...props} >
            <SimpleForm>
                <BooleanInput source="deleted" />
                <TextInput fullWidth source="question" validate={[required()]} />
                <TextInput fullWidth source="answer" validate={[required()]} />
                <ReferenceInput
                    source="subcategory"
                    reference={resource.faqSubCategory}
                    label="Subcategory"
                    validate={[required()]}
                >
                    <SelectInput
                        optionText="name"
                    />
                </ReferenceInput>
                <NumberInput source="order" validate={[required()]} />
            </SimpleForm>
        </Edit>
    );
};

const FaqEditTopToolbar = ({ basePath, data,resource}) => {
    const notify = useCustomNotify();
    const [resetImpression,{loading}] = useMutation({
        type:'faqResetImpression',
        payload:{id:data?._id}
    },{
        onSuccess:notify,
        onFailure:notify
    })
    return (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
            <Button color={"primary"} startIcon={<SettingsBackupRestoreIcon/>} disabled={loading} label={"Reset Impressions"} onClick={resetImpression}/>
        </TopToolbar>
    );
};

export default FaqEdit;