import React from 'react';
import {Datagrid, DateField, Filter, List, ReferenceInput, ReferenceField, TextField, SelectInput} from "react-admin";
import resource from "../../config/resource";

const CostLogList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} filters={<CostLogFilter/>} sort={{field: 'createdAt', order: 'DESC'}}>
            <Datagrid rowClick={"show"}>
                <ReferenceField reference={resource.costSource} source={"cost"}>
                    <TextField source={"title"}/>
                </ReferenceField>
                <TextField source={"description"}/>
                <DateField source={"date"}/>
                <TextField source={"amount"}/>
            </Datagrid>
        </List>
    );
};

const CostLogFilter = (props) => {
    return <Filter {...props}>
        <ReferenceInput
            source={"cost"}
            reference={resource.costSource}
            alwaysOn
        >
            <SelectInput optionText={"title"}/>
        </ReferenceInput>
    </Filter>
}

export default CostLogList;