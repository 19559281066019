import React from 'react';
import {BooleanField, Datagrid, DateField, List, NumberField, ReferenceField, TextField} from 'react-admin';
import resource from "../../config/resource";
import {toCsv} from "../../utils/utils";

const FaqSubcatList = (props) => {
    return (
        <List {...props} exporter={exporter}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <ReferenceField source="category" reference={resource.faqCategory} >
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="order" />
                <DateField source="createdAt" locales={"fa-IR"} />
                <DateField source="updatedAt" locales={"fa-IR"} />
                <BooleanField addLabel source="deleted" />
            </Datagrid>
        </List>
    );
};

const exporter = async (faqSubCategories,fetchRelatedRecords) => {
    const relatedCategories = await fetchRelatedRecords(faqSubCategories,'category',resource.faqCategory)
    const data = faqSubCategories.map(faqSubCategory => {
        return {
            name: faqSubCategory.name,
            category: relatedCategories[faqSubCategory.category]?.name || '-',
            order: faqSubCategory.order,
            deleted: faqSubCategory.deleted,
            createdAt: faqSubCategory.createdAt,
            updatedAt: faqSubCategory.updatedAt,
        }
    })
    toCsv(data,"faqSubcategories")
}

export default FaqSubcatList;