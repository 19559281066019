import React from 'react';
import {Edit, SimpleForm, TextInput,maxLength} from 'react-admin';

const RecommendationCategoryEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="show">
                <TextInput
                    multiline fullWidth
                    source="name"
                    label="Name"
                    validate={maxLength(200)}
                />
            </SimpleForm>
        </Edit>
    );
};

export default RecommendationCategoryEdit;