import React from 'react';
import {BooleanInput, Edit, SimpleForm, TextInput} from 'react-admin';
import DatePicker from "../../components/inputs/DatePicker";

const BulkdiscountEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <DatePicker
                    source="expirationDate"
                    label="تاریخ انقضا"
                />
                <TextInput source="description" label="توضیح"/>
                <BooleanInput source="deleted" label="پاک شده"/>
            </SimpleForm>
        </Edit>
    );
};

export default BulkdiscountEdit;