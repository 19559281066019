import React from 'react';
import {
    BooleanField,
    ChipField,
    ReferenceArrayField,
    ReferenceField,
    Show,Tab,
    TabbedShowLayout,
    SingleFieldList,
    TextField
} from 'react-admin';
import resource from "../../config/resource";
import UserChatHistory from "../user/UserChatHistory";

const SupportTicketShow = (props) => {
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={"details"}>
                    <BooleanField addLabel source="deleted" />
                    <ReferenceArrayField
                        source="labels"
                        reference={resource.supportTicketLabel}
                    >
                        <SingleFieldList >
                            <ChipField source="text" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceField label="User" source="user" reference={resource.user} link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField emptyText={"ندارد"} source="admin" reference={resource.user} link="show" label={"ادمین"}>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField emptyText={"-"} source="messages[0].text" label="متن گزارش" />
                    <TextField emptyText={"-"} locales={"fa-IR"} source="messages[1].text" label="پاسخ" />
                </Tab>
                <Tab label={"history"}>
                    <UserChatHistory userIdSource={"user"}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
export default SupportTicketShow;