import React from 'react';
import {
    BooleanField,
    ChipField,
    Datagrid,
    DateField,
    EditButton,
    NumberField,
    Pagination,
    ReferenceArrayField,
    ReferenceField,
    ReferenceManyField,
    Show,
    SingleFieldList,
    TabbedShowLayout,
    TextField,
    TopToolbar,
} from 'react-admin'
import PassedDayField from "../../components/fields/PassedDayField";
import GoalField from "../../components/fields/GoalField";
import TranslateField from "../../components/fields/TranslateField";
import PriceField from "../../components/fields/PriceField";
import resource from "../../config/resource";
import CustomTab from "../../components/Layout/CustomTab";
import {Grid} from "@material-ui/core";
import CustomExportButton from "../../components/CustomExportButton";
import {toCsv} from "../../utils/utils";
import usePermission from "../../components/permission/usePermission";
import UserChatHistory from "./UserChatHistory";
import SendSmsButton from "./SendSmsButton";
import SendNotificationButton from "./SendNotificationButton";

const UserShow = (props) => {
    const hasPermission = usePermission();
    return (
        <Show {...props} actions={<UserActions/>}>
            <TabbedShowLayout>
                <CustomTab label={"اطلاعات کاربری"}>
                    <Grid container>
                        <TextField source="name" label={'نام'}/>
                        <TextField source="username" label={"شماره تلفن"}/>
                        <TextField source="email" label={'ایمیل'} emptyText={"-"}/>
                        <NumberField source="usernamePassword" locales="fa-IR" label={'کد عبور'}/>
                        <DateField locales="fa-IR" source="createdAt" label="زمان ثبت نام"/>
                        <DateField
                            locales="fa-IR"
                            source="updatedAt"
                            label="زمان آخرین بروزرسانی"
                        />
                        <TranslateField source={"sex"} label={'جنسیت'}/>
                        <NumberField locales="fa-IR" source="height" label="قد"/>
                        <NumberField locales="fa-IR" source="weight" label="وزن"/>
                        <DateField locales={"fa-IR"} emptyText={"ندارد"} source={'subscription.expirationDate'}
                                   label={"اشتراک"}/>
                        <Grid item xs={12}>
                            {hasPermission(`${resource.user}.show.roles`) ? (
                                <ReferenceArrayField source="roles" reference={resource.role} label={'نوع کاربر'}>
                                    <SingleFieldList linkType={false}>
                                        <ChipField source="name"/>
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            ) : null}
                        </Grid>
                    </Grid>
                </CustomTab>
                <CustomTab label={"خرید‌های موفق"}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <CustomExportButton exporter={invoiceExporter} queryResource={resource.invoice}
                                                filter={record => ({purchased: true, user: record.id})}/>
                        </Grid>
                    </Grid>
                    <ReferenceManyField filter={{purchased: true}} label="" reference={resource.invoice} target="user"
                                        pagination={<Pagination/>}>
                        <Datagrid>
                            <DateField locales="fa-IR" source="createdAt" label="زمان خرید"/>
                            <PriceField source={"amount"} label={"قیمت خریداری شده"}/>
                            <ReferenceField
                                source="plan"
                                reference={resource.plan}
                                label="قیمت اشتراک"
                                link={false}
                            >
                                <PriceField source="price"/>
                            </ReferenceField>
                            <ReferenceField
                                source="plan"
                                reference={resource.plan}
                                label="نوع اشتراک"
                                link={false}
                            >
                                <TextField source="title"/>
                            </ReferenceField>
                            <ReferenceField emptyText={"-"} link={false} source="promotion"
                                            reference={resource.promotion}>
                                <TextField source="title"/>
                            </ReferenceField>
                            <TranslateField source={"market"} label={"مارکت"}/>
                            <TranslateField source={"status"} label="وضعیت"/>
                            <ReferenceField emptyText={"-"} link={false} source="discount" reference={resource.discount}
                                            label="درصد تخفیف">
                                <NumberField source="percent" locales="fa-IR"/>
                            </ReferenceField>
                            <ReferenceField emptyText={"-"} link={false} source="discount" reference={resource.discount}
                                            label="کد تخفیف">
                                <TextField source="code"/>
                            </ReferenceField>
                            <BooleanField addLabel source="refund" label="بازپرداخت"/>
                        </Datagrid>
                    </ReferenceManyField>
                </CustomTab>
                <CustomTab label={"رژیم‌های پاک نشده"}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <CustomExportButton exporter={dietExporter}
                                                filter={record => ({deleted: true, user: record.id})}
                                                queryResource={resource.diet}/>
                        </Grid>
                    </Grid>
                    <ReferenceManyField
                        label=""
                        reference={resource.diet}
                        target="user"
                        filter={{deleted: false}}
                        pagination={<Pagination/>}
                    >
                        <Datagrid
                            rowClick="show"
                        >
                            <DateField locales="fa-IR" source="createdAt" label="زمان ساخت رژیم"/>
                            <DateField source={"startDate"} locales={"fa-IR"} label="تاریخ شروع رژیم"/>
                            <PassedDayField source={"createdAt"} label="روز رژیم"/>
                            <GoalField source={'goal'}/>
                            <TranslateField source={"difficulty"} label={"درجه سختی"}/>
                            <NumberField source="calorie" label="کالری"/>
                            <BooleanField addLabel source="isCanceled" label="لغو شده"/>
                            <DateField source="cancelDate" locales="fa-IR" label="زمان لغو"/>
                            <BooleanField addLabel source="deleted" label="پاک شده"/>
                        </Datagrid>
                    </ReferenceManyField>
                </CustomTab>
                <CustomTab label={"گزارشات"}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <CustomExportButton exporter={supportTicketExporter} filter={record => ({user: record.id})}
                                                queryResource={resource.supportTicket}/>
                        </Grid>
                    </Grid>
                    <ReferenceManyField
                        label=""
                        reference={resource.supportTicket}
                        target="user"
                        pagination={<Pagination/>}
                    >
                        <Datagrid
                            rowClick="show"
                        >
                            <ReferenceField label="Admin" source="admin" reference={resource.user} link="show">
                                <TextField source="name"/>
                            </ReferenceField>
                            <ReferenceField emptyText={"ندارد"} label={"کتگوری"} source="faq" reference={resource.faq}>
                                <TextField source="question"/>
                            </ReferenceField>
                            <ReferenceArrayField
                                source="labels"
                                reference={resource.supportTicketLabel}
                            >
                                <SingleFieldList>
                                    <ChipField source="text"/>
                                </SingleFieldList>
                            </ReferenceArrayField>
                            <TextField source="ticketStatus"/>
                            <DateField source="createdAt" label="Create date" locales={"fa-IR"}/>
                            <DateField source="updatedAt" label="Last Update" locales={"fa-IR"}/>
                            <DateField emptyText={"-"} source="messages[1].dateTime" label="Reply Date"
                                       locales={"fa-IR"}/>
                            <BooleanField addLabel source="deleted"/>
                        </Datagrid>
                    </ReferenceManyField>
                </CustomTab>
                <CustomTab label={"یادداشت‌ها"}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <CustomExportButton exporter={notesExporter} filter={record => ({id: record.notes})}
                                                queryResource={resource.note}/>
                        </Grid>
                    </Grid>
                    <ReferenceArrayField source="notes" reference={resource.note} label="" fullWidth>
                        <Datagrid rowClick="edit">
                            <DateField locales="fa-IR" source="createdAt" label="زمان ساخت"/>
                            <DateField
                                locales="fa-IR"
                                source="updatedAt"
                                label="زمان آخرین بروزرسانی"
                            />
                            <ReferenceField reference={resource.user} source="author" label="نویسنده">
                                <TextField source="name"/>
                            </ReferenceField>
                            <TextField source="text" label="متن یادداشت" fullWidth/>
                        </Datagrid>
                    </ReferenceArrayField>
                </CustomTab>
                <CustomTab label={"فعالیت‌‌‌ها"}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <CustomExportButton exporter={logExporter} filter={record => ({user: record.id})}
                                                queryResource={resource.log}/>
                        </Grid>
                    </Grid>
                    <ReferenceManyField label="" reference={resource.log} target="user" pagination={<Pagination/>}>
                        <Datagrid rowClick="">
                            <DateField locales="fa-IR" source="createdAt" label="زمان ثبت"/>
                            <TextField source="entity" label="نوع فعالیت"/>
                            <TextField source="text" label="توضیح"/>
                        </Datagrid>
                    </ReferenceManyField>
                </CustomTab>
                <CustomTab label={"همه‌ی خرید‌ها"}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <CustomExportButton exporter={invoiceExporter} queryResource={resource.invoice}
                                                filter={record => ({user: record.id})}/>
                        </Grid>
                    </Grid>
                    <ReferenceManyField label="" reference={resource.invoice} target="user" pagination={<Pagination/>}>
                        <Datagrid rowClick="">
                            <DateField locales="fa-IR" source="createdAt" label="زمان خرید"/>
                            <PriceField source="amount" label="قیمت خریداری شده"/>
                            <ReferenceField
                                source="plan"
                                reference={resource.plan}
                                label="قیمت اشتراک"
                                link={false}
                            >
                                <PriceField source="price"/>
                            </ReferenceField>
                            <ReferenceField
                                source="plan"
                                reference={resource.plan}
                                label="نوع اشتراک"
                                link={false}
                            >
                                <TextField source="title"/>
                            </ReferenceField>
                            <ReferenceField emptyText={"-"} link={false} source="promotion"
                                            reference={resource.promotion}>
                                <TextField source="title"/>
                            </ReferenceField>
                            <TranslateField source={"market"} label="مارکت"/>
                            <TranslateField source={"status"} label="وضعیت"/>
                            <ReferenceField emptyText={"-"} link={false} source="discount" reference={resource.discount}
                                            label="درصد تخفیف">
                                <NumberField source="percent" locales="fa-IR"/>
                            </ReferenceField>
                            <ReferenceField emptyText={"-"} link={false} source="discount" reference={resource.discount}
                                            label="کد تخفیف">
                                <TextField source="code"/>
                            </ReferenceField>
                            <BooleanField addLabel source="refund" label="بازپرداخت"/>
                        </Datagrid>
                    </ReferenceManyField>
                </CustomTab>
                <CustomTab label={"همه‌ی رژیم‌ها"}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <CustomExportButton exporter={dietExporter} filter={record => ({user: record.id})}
                                                queryResource={resource.diet}/>
                        </Grid>
                    </Grid>
                    <ReferenceManyField label="" reference={resource.diet} target="user" pagination={<Pagination/>}>
                        <Datagrid
                            rowClick="show"
                        >
                            <DateField
                                locales="fa-IR"
                                source="createdAt"
                                label="زمان ساخت رژیم"
                            />
                            <DateField source={"startDate"} label="تاریخ شروع رژیم" locales={"fa-IR"}/>
                            <PassedDayField source={"createdAt"} label="روز رژیم"/>
                            <GoalField source="goal" label="هدف"/>
                            <TranslateField source="difficulty" label="درجه سختی"/>
                            <NumberField source="calorie" title="کالری"/>
                            <BooleanField addLabel source="isCanceled" label="لغو شده"/>
                            <DateField source="cancelDate" locales="fa-IR" label="زمان لغو"/>
                            <BooleanField addLabel source="deleted" label="پاک شده"/>
                        </Datagrid>
                    </ReferenceManyField>
                </CustomTab>
                <CustomTab label={"تیکت‌ها"}>
                    <UserChatHistory userIdSource={"id"}/>
                </CustomTab>
            </TabbedShowLayout>
        </Show>
    );
};

const UserActions = ({data:record, ...props}) => {

    return (
        <TopToolbar>
            <SendSmsButton record={record}/>
            <SendNotificationButton record={record}/>
            <EditButton basePath={props.basePath} record={record}/>
        </TopToolbar>
    )
}

const invoiceExporter = async (invoices, fetchRelatedRecords) => {
    const relatedPlans = await fetchRelatedRecords(invoices, 'plan', resource.plan);
    const relatedPromotions = await fetchRelatedRecords(invoices, 'promotion', resource.promotion);
    const relatedDiscounts = await fetchRelatedRecords(invoices, 'discount', resource.discount);
    const data = invoices.map(invoice => {
        return {
            createdAt: invoice.createdAt,
            amount: invoice.amount,
            title: relatedPlans[invoice.plan]?.title || '-',
            price: relatedPlans[invoice.plan]?.price || '-',
            promotion: relatedPromotions[invoice.promotion]?.title || '-',
            market: invoice.market,
            status: invoice.status,
            discount: relatedDiscounts[invoice.discount]?.percent || -'',
            code: relatedDiscounts[invoice.discount]?.code || -'',
            refund: invoice.refund,
        }
    })
    toCsv(data, "invoices");
}
const dietExporter = async (diets, fetchRelatedRecords) => {
    const relatedDietTypes = await fetchRelatedRecords(diets, 'type', resource.dietType);
    const data = diets.map(diet => {
        return {
            startDate: diet.startDate,
            createdAt: diet.createdAt,
            goal: diet.goal === "preDefined" ? (relatedDietTypes[diet.type]?.name || '-') : diet.goal,
            difficulty: diet.difficulty,
            calorie: diet.calorie,
            isCanceled: diet.isCanceled,
            cancelDate: diet.cancelDate,
            deleted: diet.deleted,
        }
    })
    toCsv(data, 'diets')
}
const supportTicketExporter = async (supportTickets, fetchRelatedRecords) => {
    const relatedAdmins = await fetchRelatedRecords(supportTickets, "admin", resource.user);
    const relatedFaqs = await fetchRelatedRecords(supportTickets, "faq", resource.faq);
    const relatedSupportTicketLabels = await fetchRelatedRecords(supportTickets, "labels", resource.supportTicketLabel);
    const data = supportTickets.map(supportTicket => {
        return {
            admin: relatedAdmins[supportTicket.admin]?.name || '-',
            faq: relatedFaqs[supportTicket.faq]?.question || '-',
            labels: supportTicket.labels.map(label => relatedSupportTicketLabels[label]?.text).join(','),
            ticketStatus: supportTicket.ticketStatus,
            createdAt: supportTicket.createdAt,
            updatedAt: supportTicket.updatedAt,
            replyDate: supportTicket.messages[1].dateTime,
            deleted: supportTicket.deleted,
        }
    })
    toCsv(data, "supportTickets")
}
const notesExporter = async (notes, fetchRelatedRecords) => {
    const relatedAuthors = await fetchRelatedRecords(notes, 'author', resource.user);
    const data = notes.map(note => {
        return {
            createdAt: note.createdAt,
            updatedAt: note.updatedAt,
            author: relatedAuthors[note.author]?.name || '-',
            text: note.text,
        }
    })
    toCsv(data, "notes");
}
const logExporter = async (logs, fetchRelatedRecords) => {
    const data = logs.map(log => {
        return {
            createdAt: log.createdAt,
            entity: log.entity,
            text: log.text,
        }
    })
    toCsv(data, "logs");
}
export default UserShow;