import React from 'react';
import {Button, useMutation, useRefresh} from 'react-admin';
import useCustomNotify from "../../components/useCustomNotify";

const RefundButton = ({ record }) => {
    const refresh  = useRefresh();
    const notify = useCustomNotify();
    const [refund,{loading}] = useMutation({
        type: 'update',
        resource: 'invoice',
        payload: { id: record.id, data: { refund: !record.refund } },
    },{
        onSuccess:(res) => {
            refresh();
            notify(res)
        },
        onFailure:notify
    })
    return <Button label={record.refund ? 'حذف بازپرداخت' : 'بازپرداخت'} onClick={refund} disabled={loading} variant={"outlined"} />;
};
export default RefundButton;