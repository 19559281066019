import React from 'react';
import {Show,SimpleShowLayout,BooleanField,TextField,ReferenceField,NumberField,DateField} from 'react-admin';
import resource from "../../config/resource";
const FaqShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <BooleanField addLabel source="deleted" />
                <TextField source="question" />
                <TextField source="answer" />
                <ReferenceField source="subcategory" reference={resource.faqSubCategory} >
                    <TextField source="name" />
                </ReferenceField>
                <NumberField source="order" />
                <NumberField source="impressions" />
                <NumberField source="positive" />
                <NumberField source="negative" />
                <DateField source="createdAt" locales={"fa-IR"}/>
                <DateField source="updatedAt" locales={"fa-IR"}/>
            </SimpleShowLayout>
        </Show>
    );
};

export default FaqShow;