import React from 'react';
import {Create,SimpleForm,TextInput,required,ReferenceArrayInput,CheckboxGroupInput} from 'react-admin';
import {phoneNumberValidation} from "../../utils/validation";
import resource from "../../config/resource";
const UserCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput source="name" label="نام" validate={[required()]} />
                <TextInput
                    source="username"
                    label="شماره تلفن"
                    validate={[required(),phoneNumberValidation]}
                />
                <ReferenceArrayInput validate={required()} source="roles" reference={resource.role} label="نوع کاربر">
                    <CheckboxGroupInput source="name" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;