import Service from "./Service";
import {httpClient} from "../config/client";

class SmsService extends Service {
    sendTest(params) {
        return httpClient({
            URL: `${this.URL}/sendtest`, method: "POST", data: {
                data: params.data,
                testUsers: params.testUsers
            }
        })
    }
    send(params){
        return httpClient({URL:`${this.URL}/send/${params.id}`,method:'PUT'})
    }
}

export default SmsService;