import React from 'react';
import {Create} from "react-admin";
import AutomaticSmsForm from "./AutomaticSmsForm";

const AutomaticSmsCreate = (props) => {
    return (
        <Create {...props}>
           <AutomaticSmsForm/>
        </Create>
    );
};

export default AutomaticSmsCreate;