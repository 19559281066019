import React from 'react';
import {Create, SimpleForm, TextInput,maxLength} from 'react-admin';

const RecommendationCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput
                    multiline fullWidth
                    source="name"
                    validate={maxLength(200)}
                    label="Name"
                />
            </SimpleForm>
        </Create>
    );
};

export default RecommendationCategoryCreate;