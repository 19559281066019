import React from 'react';
import {Create, TextInput, SimpleForm, required} from 'react-admin';
const CostSourceCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect={"list"}>
                <TextInput source={"title"} validate={[required()]}/>
            </SimpleForm>
        </Create>
    );
};

export default CostSourceCreate;